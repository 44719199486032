module.exports = {
   "menu.adminPanel": "admin panel",
   "menu.teachPanel": "Teach Panel",
   "menu.studPanel": "Stud Panel",
   "menu.Home": "Home",
   "menu.HomeOne": "home one ",
   "menu.HomeTwo": "home two ",
   "menu.HomeThree": "home three ",
   "menu.fashion": "Fashion",
   "menu.shop": "shop",
   "menu.gadgets": "gadgets",
   "menu.accessories": "accessories",
   "menu.categories": "categories",
   "menu.pages": "pages",
   "menu.session": "session",
   "menu.contact us": " contact us",
   "menu.men": "men",
   "menu.women": "women",
   "menu.t-shirts": "t-shirts",
   "menu.jeans": "jeans",
   "menu.shoes": "shoes",
   "menu.wallet": "wallet",
   "menu.westernWear": "western wear",
   "menu.ethnicWear": "ethnic wear",
   "menu.sportsWear": "sports wear",
   "menu.headPhones": "headPhones",
   "menu.laptop": "laptop",
   "menu.speaker": "speaker",
   "menu.watch": "watch",
   "menu.jewellery": "jewellery",
   "menu.belts": "belts",
   "menu.handBag": "handBag",
   "menu.productDetail": "product detail",
   "menu.cart": "cart",
   "menu.checkout": "checkout",
   "menu.payment": "payment",
   "menu.blogDetail": "blog detail",
   "menu.userProfile": "user profile",
   "menu.faq": "faq",
   "menu.404Page": "404 page",
   "menu.signIn": "sign in",
   "menu.register": "register",
   "menu.forgotPassword": "forgot password",
   "menu.thankYou": "thank you",
   "menu.aboutUs": "about us",
   "menu.termsAndConditions": "terms and conditions",
   "menu.privacyPolicy": "privacy policy",
   "home.howWorks":"How this works",
   "home.studyAreas":"Featured Study Areas",
   "home.customerReviews":"Customer Reviews",
   "account.menu.classes":"Classes",  
   "account.menu.profile":"Profile",
   "account.menu.address":"Address",
   "account.menu.cards":"Saved Cards",
   "account.menu.logout":"Logout",
   "account.hi":"Hi, ",
   "account.completeProfile":"! Please, complete your profile for a better experience.",  
   "account.profile.info":"Profile Infomation",
   "account.profile.email.info":"Warning! <b>Check your email and confirm your registration</b> to receive important notifications about your classes and account. If the email went to your <b>spam folder</b>, please <b>save our contact to your whitelist so you don't miss any updates</b>",
   "account.fname":"First Name:",
   "account.lname":"Last Name:",
   "account.email":"Email:",  
   "account.gender":"Gender",
   "account.male":"Male",
   "account.female":"Female",
   "account.undefined":"I prefer not to say",
   "account.birthDate":"Birth Date",
   "account.mobile":"Mobile Phone",
   "account.edit.edit":"Edit Profile Information",
   "account.edit.fnameReq":"First Name *",
   "account.edit.lnameReq":"Last Name *",
   "account.edit.emailReq":"Email *",
   "footer.whyWeDo": "Why we do what we do?",
   "footer.usefulLinks": "Usefull Links",
   "footer.socialNetworks": "Social Networks",  
   "footer.home": "Home",
   "footer.shop": "Shop",
   "footer.termsAndCondition": "Terms and Condition",
   "footer.PrivacyPolicy": "Privacy Policy",
   "footer.Faq": "FAQ",
   "footer.contactUs": "Contact Us",
   "footer.signIn": "sign in",
   "footer.register": "register",
   "footer.forgetPassword": "forget password",
   "footer.thankYou": "thank you",
   "footer.women": "women",
   "footer.men": "men",
   "footer.accessories": "accessories",
   "footer.gadgets": "gadgets",
   "admin.important":"Important",
   "admin.reports": "reports",
   "admin.invoices": "invoices",
   "admin.products": "My Classes",
   "admin.createdProducts": "Created Classes",
   "admin.publishedProducts": "Published Classes",
   "admin.profile": "profile",
   "admin.productAdd": "product add",
   "admin.purchasedProducts": "Purchased Classes",
   "admin.soldProducts": "Sold Classes",
   "admin.goToHome": "go to home",
   "admin.myFinances": "My Finances",
   "admin.receiveMoneyData": "Receive Money Data",
   "admin.products.grid": "grid class",
   "admin.products.list": "list class",
   "admin.products.actions": "Actions",
   "admin.products.first": "First",
   "admin.products.previous": "Previous",
   "admin.products.next": "Next",
   "admin.products.last": "Last",
   "admin.products.loading": "Loading...",
   "admin.products.noData": "No classes found",
   "admin.products.page": "Page",
   "admin.products.of": "of",
   "admin.products.rows": "rows",
   "admin.addProd.validate.imageReq":"Cover Image is required",
   "admin.addProd.validate.cNameReq":"Class name is required",
   "admin.addProd.validate.priceReq":"Price is required",
   "admin.addProd.validate.priceMin":"Price min is u$s20",
   "admin.addProd.validate.discMinToPrice":"Discount must be less than price",
   "admin.addProd.validate.discPriceInvalid":"Price invalid",
   "admin.addProd.validate.noDiscPrice":"For not offer discount, put 0 or leave blank",
   "admin.addProd.validate.daysReq":"Day or days is required",
   "admin.addProd.validate.categoriesReq":"Select a category",
   "admin.addProd.validate.timeReq":"Select time",
   "admin.addProd.validate.levelReq": "Select level",
   "admin.addProd.validate.durationReq": "Select duration",
   "admin.addProd.validate.durationMin": "Duration more than 0",
   "admin.addProd.validate.classTypeReq":"Select type of class",
   "admin.addProd.validate.modalityReq":"Select the modality",
   "admin.addProd.validate.quantityReq":"Select quantity",
   "admin.addProd.validate.quantityMax":"Quantity no more than 5",
   "admin.addProd.validate.descReq":"Description is required",
   "admin.addProd.validate.formError":"Please, complete all the required fields",
   "admin.editProd.validate.noPastDays":"Date should not be in the past",
   "admin.firstInfo.title":"",
   "admin.firstInfo.1":"",
   "link.clickHere":"CLICK HERE",
   "admin.firstInfo.1b":"",
   "admin.firstInfo.2":"",
   "admin.firstInfo.2b":"",
   "admin.firstInfo.3":"",
   "admin.firstInfo.3b":"",
   "admin.firstInfo.4":"",
   "admin.firstInfo.4b":"",
   "admin.firstInfo.5":"",
   "admin.firstInfo.6":"",
   "admin.firstInfo.7":"",
   "admin.firstInfo.7b":"",
   "admin.firstInfo.congrats":"",
   "admin.myFinances.link": "Link",
   "admin.myFinances.unlink": "Unlink",
   "panels.enter":"Go to class",
   "panels.search":"Search",  
   "panels.stud.cancel":"Cancel class",
   "panels.stud.pagar":"Pay",
   "panels.teach.cancel":"Cancel entire class",
   "panels.card.nextDayClass": "Next class:",
   "panels.card.startTime": "Start time:",
   "panels.card.endTime": "End time",
   "panels.card.extraInfo": "Extra information",
   "panels.card.extraInfoPH": "Paste extra info",
   "panels.card.copy": "Copy",
   "panels.card.copied": "Copied!",
   "panels.card.allowSubs": "Allow subscriptions",
   "panels.card.allowSubsTt": "Switch to allow or block new subscriptions",
   "panels.card.active": "Active",
   "panels.card.inactive": "Inactive",
   "panels.card.link": "Link to videocall:",
   "panels.card.linkPH": "Paste here the link",
   "panels.card.invalidURL": "Invalid url ",
   "panels.materials": "Class Materials",
   "panels.materials.file": "File",
   "panels.materials.download": "Download",
   "panels.materials.downloadTt": "Download now",
   "panels.materials.delete": "Delete",
   "panels.materials.deleteTt": "Delete file",
   "panels.materials.upload.success": "File uploaded successfully",
   "panels.materials.upload.error": "Could not upload the file!",  
   "panels.materials.upload.addNew": "Add new",
   "panels.gang": "Students",
   "panels.gang.lastPayment": "Last payment",
   "panels.gang.deleteTt": "Contat support to remove student",
   "panels.noRows": "No records to display",
   "signUp":"Sign Up",
   "signUp.enterDetails":"Enter your details",
   "signUp.clear":"clear",
   "signUp.alreadyHaveAccount":"already have an account? then ",
   "signUpAs.button.signUp":"Register your classes",
   "signUpAs.button.signUpNow":"Sign up now",
   "signUpAs.titleRegister":"Join us!",
   "signUpAs.subTitleRegister":"Register to be a teacher:",
   "signUpAs.sales1.line1":" More sales",
   "signUpAs.sales1.line2":" 24/7",
   "signUpAs.sales1.line3":" all the year",
   "signUpAs.sales2.line1":" Win",
   "signUpAs.sales2.line2":" 70%",
   "signUpAs.sales2.line3":" of the class at the moment",
   "signUpAs.sales3.line1":" Live",
   "signUpAs.sales3.line2":" on Real Time",
   "signUpAs.sales4.line3":" with students",
   "signUpAs.teachReviews":"Teachers Reviews",
   "signIn":"Sign In",
   "sinIn.enterDetails": "user sign in",
   "signIn.rememberMe": "Remember Me",
   "signIn.forgotPass": "Forgot Password?",
   "signIn.noAccount": "Don't have an account? ",
   "signIn.createOne": "Click here to create one",
   "forgotPass": "Forgot Password?",
   "forgotPass.noProblem": "No Problem",
   "forgotPass.haveAccount":"If have an account then ",
   "submit":"submit",
   "form.firstName":"first name",
   "form.lastName":"last name",
   "form.email":"Email",
   "form.confirmEmail": "retype your email",
   "form.password":"password",
   "form.retypePassword":"retype Password",
   "form.val.firstName":"Enter a name",
   "form.val.firstNameRequired":"Name is required",
   "form.val.firstNameOnlyLetters":"Only letters",  
   "form.val.lastName":"Enter a last name",
   "form.val.lastNameRequired":"Last name is required",
   "form.val.email":"Enter your email",
   "form.val.invalidEmail":"Enter a valid email",
   "form.val.emailRequired":"Email is required",
   "form.val.confirmEmail":"Enter your email",
   "form.val.confirmEmailRequired":"Confirm your email",
   "form.val.confirmEmailNoMatch":"Email does not match",
   "form.val.passwordRequired":"Enter your password",
   "form.val.shortPassword":"Password must contain at least 8 characters",
   "form.val.confirmPassword":"Enter your password",
   "form.val.confirmPasswordRequired":"Confirm your password",
   "form.val.confirmPasswordNoMatch":"Password does not match", 
   "form.val.invalidMobile":"Invalid Mobile Phone",
   "form.val.noEmailInDesc":"Don't enter contact details or external sites",
   "form.val.noURLInDesc":"Don't enter external sites",
   "form.val.noMobileInDesc":"Don't enter contact details",
   "form.val.formHasErrors":"Form has errors!",
   "alert.addProduct.publish.succes":"Perfect! Class was publish to the store successfully!",
   "alert.addProduct.draft.success":"Just a little more! Your class is on draft, to sell you must publish it",
   "alert.addProduct.noAvatar":"If you didn't, before create a product, add your Avatar in profile settings, please!",
   "alert.addProduct.error":"Save Error, try again later",
   "alert.addProduct.limitOfProds": "Wow! You reached the limit of classes created. We are working so you can create more classes.",
   "alert.updateSoldProduct.success":"Class updated successfully!",  
   "alert.updateProduct.publish.success":"Perfect! Updated and published class success!",
   "alert.updateProduct.draft. success":"Just a little more! Class updated as draft, to sell you must publish it",
   "alert.updateProduct.error":"Update Error, try again later",
   "alert.rating.success":"New rating success!",
   "alert.rating.error":"New rating Error, try again later",
   "alert.deleteProduct.warning":"Are you sure you want to delete this product permanently?",
   "alert.teach.deleteProduct.warning":"Attention! Are you sure you want to permanently cancel this class? {br} Esta ação irá cancelar a aula para todos os alunos!",
   "alert.stud.deleteProduct.warning":"Attention! Are you sure you want to permanently cancel this class? {br} It will not be possible to undo this operation!", 
   "alert.deleteProduct.success":"Delete class success!",
   "alert.deleteProduct.error":"Delete Error, try again later",
   "alert.disableSubscriptions.warning":"Attention! The class will still on the shop but will be dsiable for new subscriptions.{br}Are you sure you want to continue? The class will still on the shop but will be dsiable for new subscriptions.",
   "alert.finance.desativarPagamento.warning":"Attention! If you unlink the payment method, you will not be able to continue charging for classes already sold and all your created classes will be converted to draft.{br}Are you sure you want to continue?",
   "alert.updateAvatar.success":"Update avatar success!",
   "alert.updateAvatar.warning":"Remember the max file size is 5MB and the files suported: .jpg, .png, .gif",  
   "updateFile.sizeError":"The max file size is 5MB",  
   "updateFile.typeError":"the files suported: .jpg, .png, .gif",  
   "alert.updateData.success":"Data updated!",
   "alert.updateData.error":"There was a problem updating, please log out and login and try again",
   "alert.shop.addProduct.cart.success":"Your class is successfully added in cart",
   "alert.shop.addProduct.wishList.success":"Your class is successfully added in wishlist!",
   "router.backTo.shop":"Back to shop",
   "router.backTo.classes":"Back to classes",
   "product.preview":"Preview",
   "product.cImage":"Cover Image",
   "product.image":"image",
   "product.code":"Product Code",  
   "product.cName":"Class Name",
   "product.name":"Name",
   "product.price":"Price",
   "product.mPrice":"Month Price",
   "product.discPrice":"Discount Price",
   "product.mDiscPrice":"Month Discount Price",
   "product.category":"Category",
   "product.days":"Days",  
   "product.day.monday":"Monday",
   "product.day.tuesday":"Tuesday",
   "product.day.wednesday":"Wednesday",
   "product.day.thursday":"Thursday",
   "product.day.friday":"Friday",
   "product.day.saturday":"Saturday",
   "product.day.sunday":"Sunday",
   "product.time":"Time",
   "product.time.morning":"Morning",
   "product.time.afternoon":"Afternoon",
   "product.time.night":"Night",
   "product.level":"Level",
   "product.level.basic":"Basic",
   "product.level.intermediate":"Intermediate",
   "product.level.advanced":"Advanced",
   "product.hasDuration":"Has duration",
   "product.hasDuration.yes":"Yes",
   "product.hasDuration.no":"No",
   "product.hasDuration.startDate":"Start Date",
   "product.duration": "Monthly duration",
   "product.duration2": "Duration",
   "product.indetDuration": "Indeterminate",
   "product.Month": "Month",
   "product.Months": "Months",
   "product.classType":"Type of class",
   "product.classType.Particular":"Particular",
   "product.classType.Extention":"Extention(Libre)",
   "product.classType.School":"School Reforce",
   "product.classType.Tecnical":"Tecnical",
   "product.classType.Prevestivular":"Pre-Vestibular",
   "product.classType.ParaConcurso":"Para Concurso",
   "product.classType.Pro":"Profetionalizant",
   "product.classType.Degree":"Degree",
   "product.classType.PosDegree":"Posdegree",
   "product.classType.MBA":"MBA",
   "product.classType.Maestry":"Maestry",
   "product.classType.PHD":"Doctor/PHD",
   "product.modality":"Modality of class",
   "product.modality.studentAddress":"Student Address",
   "product.modality.teacherAddress":"Teacher Address",
   "product.modality.institution":"In institution",
   "product.modality.videoCall":"Video call",
   "product.modality.videoOnDemand":"Video On Demand",
   "product.institution":"Institution",
   "product.institution.yes":"Yes",
   "product.institution.no":"No",
   "product.certified":"Certified",
   "product.certified.yes":"Yes",
   "product.certified.no":"No",  
   "product.quantity":"Max students",
   "product.addDesc":"Add Description and explain the themes",
   "product.rate":"Post Review",
   "product.perMonth":"per month",
   "product.availability":"Availability",
   "product.tags":"Tags",
   "product.desc":"Description",
   "product.rating":"Rating",
   "product.reviews":"Reviews",
   "product.noReviews":"This item doesn't have a reviews yet",
   "product.purchased.reviews.title": "Rate and Review",
   "product.purchased.reviews.alert": "This panel is used to rate classes you have taken. Remember not to use vulgar or offensive language. This review is used to encourage other students to take the class. Thank you very much for the feedback!",
	//
   "product.purchased.reviews.sub1": "How many stars you want to give?",
   "product.purchased.reviews.sub2": "Make a comment",
   "product.purchased.reviews.placeholder": "I loved the teacher class, he is so great...",
   "product.purchased.reviews.maxLength": "500 characters is the max length.",  
   "product.comingSoon":"Coming Soon",
   "product.startClass":"Start Class",
   "product.classify":"Classify",
   "product.status":"Status",
   "product.status.published":"Published",
   "product.status.draft":"Draft",
   "product.status.paid":"Paid",
   "product.status.nearExpired":"Near to expire",
   "product.status.expired":"Expired",
   "product.button.EditTt":"Edit",
   "product.button.DeleteTt":"Delete",
   "product.subscriptions":"Subscriptions",
   "button.showAll":"Show All",
   "button.publish":"Publish",
   "button.save":"Save",
   "button.saveInDraft": "Draft",
   "button.create":"Create",
   "button.discard":"Discard",
   "button.cancel":"Cancel",
   "button.edit":"Edit",
   "shop.addCart":"Add to Cart",
   "shop.viewCart":"View Cart",
   "shop.buyNow":"Buy Now",
   "shop.productDetail.inStock":"In Stocks",
   "shop.productDetail.oneStock":"Just {stock} stock available!",
   "shop.productDetail.moreStock":"Just {stocks} stock available!",
   "shop.productDetail.outStock":"Out of Stock",
   "shop.productDetail.suscribed":"Student/s suscribed",
   "shop.productDetail.alsoLike":"You Might Also Like",
   "shop.productDetail.alreadyPurchased":"Yey! You are already student of this class, for do a new month payment please go to the student panel.",  
   "shop.productDetail.buy.error":"Wow! An error ocurred in the process. Try again later, please.",  
   "shop.title":"See our classes",
   "shop.filter.searchProducts":"Search Products",
   "shop.filter.featured":"Featured",
   "shop.filter.popular":"Popular",
   "shop.filter.lowPrice":"Lowest Price",
   "shop.filter.highPrice":"Highest Price",
   "shop.filter.showLess":"Show less",
   "shop.filter.showMore":"Show more",
   "shop.filter.below":"Below",
   "shop.filter.above":"Above",
   "shop.filter.all":"All",
   "shop.filter.priceRange":"Enter Price Range",
   "shop.filter.priceRange.go":"Go",
   "shop.filter.noDuration":"No final date",
   "shop.filter.durationRange":"Enter Duration Range",
   "shop.filter.certified":"Display only certified",
   "shop.filter.institution":"Display only intitutions",  
   "shop.filter.clearFilters":"Clear all filters", 
   "socialMedia.shareNow":"Share Now",
   "socialMedia.facebook":"Facebook",
   "socialMedia.instagram":"Instagram",
   "socialMedia.followFacebook":"Keep in touch and subscribe in our Facebook page",
   "socialMedia.followInstagram":"Keep in touch and follow our Instagram channel",
   "socialMedia.shareTitle":"Follow our comunity: ",
   "subscribe.title":"Subscribe our Newsletter",
   "subscribe.desc":"Stay up to date with our latest new and products",
   "subscribe.name":"enter your name",
   "subscribe.email":"enter your email",
   "subscribe.student":"Student",
   "subscribe.teacher":"Teacher",
   "subscribe.popUp.title":"Don´t miss it",
   "subscribe.popUp.desc":"Very soon we'll release the classes, we wish we can count on you.",
   "subscribe.popUp.close":"May be later",
   "contact.pageTitle":"Contact Us",
   "contact.pageDesc":"Share your feedback with us.",
   "contact.contactInfo":"Contact Info",
   "contact.contactDesc":"If you have any problems,suggestions and feedback then please feel free to contact with us. Choose our communication soruces. We will love to hear from you.",
   //"contact.whatsapp": "whatsapp",
   "contact.tel":"11 98128 8729",
   //"contact.mail":"Mail",
   "contact.mail":"contato@todasaulas.com",
   //"contact.ourOffice":"Our office",
   "contact.addressDesc":"Cemetery Street,MEDFORD - 1452451 New York",
   "contact.whereOperate":"Where we operate",
   "contact.followInSocial":"Follow us in our social networks",
   "terms.title":"terms & conditions",
   "terms.desc":"Please read and contact with if you have some issue.",
   "privacyPol.title":"privacy policy",
   "privacyPol.desc":"Your information is fully secure with us.",  
   "faq.title":"Frequently Asked Question",
   "faq.desc":"We explained the basics question in this section.",
   "faq.submitRequest":"submit request",
   "bannerCat.seeMore":"See more",
   "bannerCat.shopNow":"Shop now",
   "bannerCat.seeAll":"See all!",
   "bannerProf.title":"I want to teach",
   "bannerProf.desc":"Don't be out of the group of teachers that are teaching arround all the world",
   "bannerProf.button":"Sign up for free now!",
   "404.desc":"Oops, we can’t seem to find the page you’re looking for.",
   "404.toHome":"go to home",
   "yes":"Yes",
   "no":"No",
   "close": "Close",
   "iAgree": "I Agree",
   "retrieve.data.error": "Failed to retrieve data. Try again later please",
   "teach.finance.activePayment.title" : "Active Payment",
   "teach.finance.activePayment.subTitle" : "Chose a payment method",
   "teach.finance.payment.mpAccount": "Link MercadoPago Account",  
   "teach.finance.activePayment.desc": "Active here for receive the students payments, for more info {link}.",
   "teach.finance.unlinkPayment.mpAccount": "Unlink MercadoPago Account",
   "teach.finance.unlinkPayment.desc": "Click here to desvinculate your payment processor. Warning!! You will no longer receive more payments and yous classes published will come back to draft!",
   "button.link": "Ativar",
   "button.unlink": "Desativar",  
   "alert.stud.warning.payDateClose": "Your payment is about to become due. Please pay to continue studying",
   "alert.stud.error.mustPay": "Your payment was due. Please pay to continue studying",
   "alert.stud.error.teacherRecentlyDesubscribed":"You no longer have access to some class(es), because the Instructor does not have the payment method activated.",
   "alert.stud.error.teacherNoLinked": "Tell the teacher to link with a payment method",
   "alert.teach.finance.vinculate.success": "Great! Now you can publish your classes",
   "alert.teach.finance.vinculate.error": "Wow! An error ocurred in the process. Try again later, please.",
   "alert.teach.finance.vinculate.errorOtherAccount": "Wow, this payment account is vinculated with other user, plase use another payment account to start selling.",
   "alert.teach.finance.unlink.success": "Your account was unlink successfully. Keep in mind that you are not able to sell anymore!",
   "alert.title.info":"Information",
   "alert.title.warning":"Warning!",
   "alert.title.error":"Error",
   "alert.teach.draft.info":"You have class(es) on draft, to start sell it/them you have to publish.",
   "alert.teach.error.mustSubscribe":"Important! Active the payment method to publish and start selling your classes.",
   "alert.teach.sold.error.subsForTeach":"Active the payment method in order to access your sold classes.",
   "alert.teach.error.mustSubsOrWillBlocked":"Important! You only have {days} da(s) to acces your classes at least you active the payment method.",
   "alert.shop.warning.pausedClass":"This class is temporaly paused. We are improving some options to offer the best and cover you expectations!",
   "alert.teach.edit.error.soldNoDraft": " Wow! It is not possible to put as a draft a class already sold! Try contact support if you have questions.",
   "alert.teach.products.error.soldNoDelete": "Wow! It is not possible to delete a class already sold!  Try contact support if you have questions.",
   "cookieNotification.title": "Todas Aulas Cookie and Privacy Policy",
   "cookieNotification.readPolicy": "Read Privacy Policy",
   "cookieNotification.updatePolicy": "Todas Aulas have updated our {link}, effective Jul 8, 2022. These updates include information about the personal data we collect, how we may handle that personal data, and rights you may have with respect to your personal data. Your continued use of our site means that you accept the updated Privacy Policy."
}
