/**
 * featuresv2 component
 */
/* eslint-disable */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '../../util/IntlMessages';
import AppConfig from '../../../src/constants/AppConfig';

function SocialIconsFollow() {

   return (
      <div>
         <Grid container spacing={4} className="iron-features-v2 my-0">
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="iron-col py-0 mb-25 mb-md-0">
			<a href={AppConfig.facebookPage} target="_blank">
			<div className="iron-features-post iron-shadow p-15 d-md-flex rounded ">
				<div className="iron-social-icon-f py-md-0 py-15 mx-auto">
					<span className="d-flex justify-content-center align-items-center">
						<i className="zmdi zmdi-facebook"></i>
					</span>
				</div>
				<div className="iron-features-content pl-lg-25 pl-md-15 text-md-left text-center">
					<h4 className="text-unset font-bold mb-10">
					<IntlMessages id="socialMedia.facebook"/>
					</h4>
					<p className="text-unset mb-5">
					<IntlMessages id="socialMedia.followFacebook"/>
					</p>
				</div>
			</div>
			</a>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="iron-col py-0 mb-25 mb-md-0">
			<a href={AppConfig.instagramPage} target="_blank">
			<div className="iron-features-post iron-shadow p-15 d-md-flex rounded">
				<div className="iron-social-icon-i py-md-0 py-15 mx-auto">
					<span className="d-flex justify-content-center align-items-center">
						<i className="zmdi zmdi-instagram"></i>
					</span>
				</div>
				
				<div className="iron-features-content pl-lg-25 pl-md-15 text-md-left text-center">
					<h4 className="text-unset font-bold mb-10">
					<IntlMessages id="socialMedia.instagram"/>
					</h4>
					<p className="text-unset mb-5">
					<IntlMessages id="socialMedia.followInstagram"/>
					</p>
				</div>
			</div>
			</a>
			</Grid> 
         </Grid>
      </div>
   )
}

export default SocialIconsFollow;
