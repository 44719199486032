/**
 * Portuguese locale
 */
import ptMessages from '../locales/pt_BR';

const ptLang = {
    messages: {
        ...ptMessages
    },
    locale: 'pt-BR'
};
export default ptLang;