/**
 * App Routes
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import RctAdminPanel from './rct-admin-panel';
import SweetAlert from 'react-bootstrap-sweetalert';
import { hideAlert } from '../actions/action';
// router service
import routerService from "../services/_routerService";
import TransparentContentLoader from '../components/global/loaders/TransparentContentLoader';

class AdminLayout extends Component {

   render() {
      const { match } = this.props;
      return (
		<Fragment> 
			<RctAdminPanel>
				{routerService && routerService.map((route, index) => {
				return (
					<Route key={index} path={`${match.url}/${route.path}`} component={route.component} />
				)
				})}
			</RctAdminPanel>
			<SweetAlert
			success={this.props.alertType === 'success'}
			error={this.props.alertType === 'error'}
			warning={this.props.alertType === 'warning'}
			title=''
			confirmBtnText="Ok"
			confirmBtnBsStyle="warning"
			className="iron-alert-box"
			show={this.props.showAlert}
			onConfirm={this.props.hideAlert}
			onCancel={this.props.hideAlert}
			closeOnClickOutside
			>
			{this.props.alertMessage}
			</SweetAlert>
			<TransparentContentLoader />
		</Fragment>  
      );
   }
}

const mapStateToProps = ({ appSettings }) => {
	const { showAlert, alertMessage, alertType } = appSettings;
	return { showAlert, alertMessage, alertType };
 }

export default withRouter(connect(mapStateToProps, {
	hideAlert
 })(AdminLayout));
