/**
 * Main App
 */
import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

// css
import './lib/embryoCss';

// App locale
import AppLocale from './lang';

// layout
import HeaderOne from './components/layouts/headers/HeaderOne';
import HeaderTwo from './components/layouts/headers/HeaderTwo';
import HeaderThree from './components/layouts/headers/HeaderThree';
import FooterOne from './components/layouts/footers/FooterOne';

// review component
import ProductReview from './components/global/popups/ProductReview';

// import helmet
import { Helmet } from "react-helmet";

// Add Loaders
import {
  AsyncHomePageOneComponent,
  //AsyncHomePageTwoComponent,
  //AsyncHomePageThreeComponent,
  //AsyncAboutUSComponent,
  //AsyncCartComponent,
  //AsyncCheckOutComponent,
  //AsyncBlogDetailComponent,
  //AsyncAccessoriesComponent,
  AsyncContactUsComponent,
  AsyncFAQComponent,
  //AsyncPaymentOptionsComponent,
  AsyncPrivacyPolicyComponent,
  AsyncTermAndConditionComponent,
  AsyncProductDetailComponent,
  //AsyncInvoiceComponent,
  AsyncShopComponent,
  AsyncSignInPageComponent,
  AsyncSignUpPageComponent,
  AsyncSignUpAsPageComponent,
  AsyncForgetPasswordComponent,
  //AsyncThankYouComponent,
  AsyncUserAccountComponent,
  AsyncPageNotFoundComponent
} from './util/AsyncRoutes';

// private Routes helper
import PrivateRoute from './util/PrivateRoutes';

// actions
import { hideAlert } from './actions/action';

// footer data
import footerData from './assets/data/footerData';
//import ThemeOptions from './components/ThemeOptions/ThemeOptions';
import AdminLayout from './components/AdminLayout';

// const EcommerceLayout = () => 

class App extends React.Component {

	componentDidMount() {
		const { darkMode,rtlLayout } = this.props;
		this.rtlLayoutOption(rtlLayout);
		this.darkModeHanlder(darkMode);
	}

	rtlLayoutOption(rtlLayout) {
      if (rtlLayout === true) {
         document.body.classList.add("rtl-layout");
      }
      else {
         document.body.classList.remove("rtl-layout");
      }
	}
	
	darkModeHanlder(darkMode) {
		if (darkMode === true) {
			document.body.classList.add("dark-mode");
		}
		else {
			document.body.classList.remove("dark-mode");
		}
	}
   /**
    * method for update window top when new page render
    */
   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         window.scrollTo(0, 0);
      }
   }

   getUrl(pathname) {
      let pathArray = pathname.split('/');
      return `/${pathArray[1]}` === '/admin-panel' ? true : false;
	}

   render() {
      const { location } = this.props;
		const { selectedLocale, alertType,theme } = this.props;
		const currentAppLocale = AppLocale[selectedLocale.locale];
		console.log(theme)
      return (
         <MuiThemeProvider theme={theme}>
            <IntlProvider
               locale={currentAppLocale.locale}
               messages={currentAppLocale.messages}
            >
               <Fragment>
                  {this.getUrl(location.pathname) ?

                     <PrivateRoute path="/admin-panel" component={AdminLayout} />
                     :
                     <div className="app-container">
						<Helmet>
        					<html lang="pt" />
							<title>Todas Aulas - A sua aula voce encontra aquí</title>
							<meta name="description" content="Todas Aulas é uma Plataforma para aprender em tempo real o que você quiser, em qualquer nível. Ensine fácil e alcance alunos em todo o Brasil. Cadastre-se grátis agora!" />
							<meta name="keywords" content="aulas, aulas videochamada, aulas ao vivo, aulas em tempo real, aprender, aprender de casa, aprender virtual, cursos, curso virtual, cursos online, ensinar online, aulas virtuais, ganhar dinero como professor, plataforma de estudos, plataforma de ensino, portal do aluno, portal do professor, estudiar de casa, ead, educação a distancia" />
							<link rel="canonical" href="https://todasaulas.com" />
							<meta property="og:url" content="https://todasaulas.com/"/>
							<meta property="og:type" content="website"/>
							<meta property="og:title" content="Todas Aulas - A sua aula voce encontra aquí"/>
							<meta property="og:description" content="Todas Aulas é uma Plataforma para aprender em tempo real o que você quiser, em qualquer nível. Ensine fácil e alcance alunos em todo o Brasil. Cadastre-se grátis agora!"/>
							<meta property="og:image" content={require('../src/assets/images/bannerta1.jpg')}/>
							<meta name="twitter:card" content="summary_large_image"/>
							<meta property="twitter:domain" content="todasaulas.com"/>
							<meta property="twitter:url" content="https://todasaulas.com/"/>
							<meta name="twitter:title" content="Todas Aulas - A sua aula voce encontra aquí"/>
							<meta name="twitter:description" content="Todas Aulas é uma Plataforma para aprender em tempo real o que você quiser, em qualquer nível. Ensine fácil e alcance alunos em todo o Brasil. Cadastre-se grátis agora!"/>
							<meta name="twitter:image" content={require('../src/assets/images/bannerta1.jpg')}/>
						</Helmet>
                        {this.props.location.pathname && this.props.location.pathname === '/home-two'
                           ?
                           <HeaderTwo />
                           :
                           <Fragment>
                              {this.props.location.pathname === '/home-three' ?
                                 <HeaderThree />
                                 :
                                 <HeaderOne />
                              }
                           </Fragment>
                        }
                        <ProductReview />
                        <Switch>
                           <Route exact path="/" component={AsyncHomePageOneComponent} />
                           {/* <PrivateRoute path="/home-two" component={AsyncHomePageTwoComponent} />
                           <Route path="/home-three" component={AsyncHomePageThreeComponent} /> */}
                           <Route path="/products/:type/:id" component={AsyncProductDetailComponent} />
                           {/* <Route path="/cart" component={AsyncCartComponent} />
                           <Route path="/check-out" component={AsyncCheckOutComponent} />
                           <Route path="/payment" component={AsyncPaymentOptionsComponent} />
                           <Route path="/final-receipt" component={AsyncInvoiceComponent} />
                           <Route path="/accessories" component={AsyncAccessoriesComponent} /> */}
                           <Route path="/shop" component={AsyncShopComponent} />
                           {/* <Route path="/about-us" component={AsyncAboutUSComponent} /> */}
                           <Route path="/term-and-condition" component={AsyncTermAndConditionComponent} />
                           <Route path="/privacy-policy" component={AsyncPrivacyPolicyComponent} />
                           <Route path="/faq" component={AsyncFAQComponent} />
                           {/* <Route path="/blogs/detail/:id" component={AsyncBlogDetailComponent} /> */}
                           <Route path="/sign-in" component={AsyncSignInPageComponent} />
                           <Route path="/sign-up" component={AsyncSignUpPageComponent} />
                           <Route path="/sign-up-as/:categories?" component={AsyncSignUpAsPageComponent} />
                           <Route path="/forget-password" component={AsyncForgetPasswordComponent} />
                           {/* <Route path="/thank-you" component={AsyncThankYouComponent} /> */}
                           <Route path="/contact-us" component={AsyncContactUsComponent} />
                           <PrivateRoute path="/account" component={AsyncUserAccountComponent} />
                           <Route path="*" component={AsyncPageNotFoundComponent} />
                        </Switch>
                        <FooterOne data={footerData} />
                        <SweetAlert
						   info={alertType === 'info'}
                           success={alertType === 'success'}
						   error={alertType === 'error'}
						   warning={alertType === 'warning'}
                           title=''
                           confirmBtnText="Ok"
                           confirmBtnBsStyle="warning"
                           className="iron-alert-box"
                           show={this.props.showAlert}
                           onConfirm={this.props.hideAlert}
                           onCancel={this.props.hideAlert}
                           closeOnClickOutside
                        >
                           {this.props.alertMessage}
                        </SweetAlert>

                     </div>
                  }
                  {/* <ThemeOptions url={this.getUrl(location.pathname)} /> */}
               </Fragment>
            </IntlProvider>
         </MuiThemeProvider>
      )
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   const { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout } = appSettings;
   return { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout };
}

export default connect(mapStateToProps, {
   hideAlert
})(App);
