/**
 * Confirmation dialog component
*/
/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import IntlMessages from '../../../util/IntlMessages';

class ConfirmationDialog extends React.Component {
   state = {
      open: false
   };

   //Define function for open confirmation dialog box
   openDialog() {
      this.setState({ open: true });
   };

   //Define function for close confirmation dialog box and callback for delete item 
   closeDialog(isTrue) {
      this.setState({ open: false });
      this.props.onConfirm(isTrue)
   };

   render() {

      return (
         <Dialog
            disableBackdropClick
            open={this.state.open}
            onClose={this.closeDialog.bind(this)}
            aria-labelledby="responsive-dialog-title"
         >  
            <DialogTitle>{this.props.message}</DialogTitle>
            <DialogActions className="px-20 pb-20 justify-content-center">
               <Button onClick={() => this.closeDialog(true)} className="button btn-active mr-15">
                  <IntlMessages id="yes"/>
               </Button>
               <Button onClick={() => this.closeDialog(false)} className="button btn-active mr-15" autoFocus>
                  <IntlMessages id="no"/>
               </Button>
            </DialogActions>
         </Dialog >
      );
   }
}

ConfirmationDialog.defaultProps = { 
   message: <IntlMessages id="alert.deleteProduct.warning"/>
};

export default ConfirmationDialog;