import AdminPanel from "../routes/admin-panel";
import StudPanel from "../routes/stud";

// routes

export default [
   {
      path: 'admin',
      component: AdminPanel
   },
   {
      path: 'stud',
      component: StudPanel
   }
]