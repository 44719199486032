import firebase from '../firebase';
import axios from 'axios';
import appConfig from '../constants/AppConfig'
const baseUrl = appConfig.backEndBaseURL;

export const getProductsByUserAuthor = async (authorId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.get(baseUrl + 'products/getByAuthor/' + authorId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const getDetailsByProduct = async (productId) => {

  return axios.get(baseUrl + 'products/getById/' + productId)
    .then(res => res);
}

export const getProductsByProductType = async (productType) => {

  return axios.get(baseUrl + 'products/' + productType)
    .then(res => res.data);
}

export const addProduct = async (item) => {
  var formData = new FormData();

  formData.append("image", item.image[0]);
  for (var key in item) {
    formData.append(key, JSON.stringify(item[key]));
  }

  const token = await firebase.auth().currentUser.getIdToken();

  return axios
    .post(baseUrl + `products`, formData, {
      headers: { authorization: `Bearer ${token}` },
      "Content-Type": "multipart/form-data",
    })
    .then((res) => res.data);
};

export const updateproduct = async (item) => {
  var formData = new FormData();

  if (typeof item.image === 'object') {
    formData.append("image", item.image[0]);
  }

  for (var key in item) {
    formData.append(key, JSON.stringify(item[key]));
  }

  const token = await firebase.auth().currentUser.getIdToken();

  return axios.put(baseUrl + 'products/', formData, {
    headers: { authorization: `Bearer ${token}` },
    "Content-Type": "multipart/form-data",
  })
    .then(res => res.data);
}

export const deleteproduct = async (productId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.delete(baseUrl + 'products/' + productId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const addrating = async (item) => {
  var formData = new FormData();

  for (var key in item) {
    formData.append(key, JSON.stringify(item[key]));
  }

  const token = await firebase.auth().currentUser.getIdToken();

  return axios
    .post(`${baseUrl}purchasedProducts/rate/${item.productId}`, item, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then((res) => res.data);
};

export const upload = async (item, onUploadProgress) => {
  let formData = new FormData();

  let userId = firebase.auth().currentUser.uid;

  formData.append("file", item.file);
  formData.append("productId", item.productId);
  formData.append("userId", userId);

  const token = await firebase.auth().currentUser.getIdToken();

  return axios
    .post(`${baseUrl}soldProducts/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${token}`
      },
      onUploadProgress,
    });
}

export const getFiles = async (productId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.get(`${baseUrl}soldProducts/materialFiles/${productId}`, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}
export const deleteSoldProduct = async (soldProductId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.delete(baseUrl + 'soldProducts/' + soldProductId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const getSoldProductDetails = async (soldProductId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.get(baseUrl + 'soldProducts/getDetails/' + soldProductId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const updatesoldProduct = async (soldProductId, data) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.put(baseUrl + 'soldProducts/' + soldProductId, data, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const deletePurchaseProduct = async (purchaseProductId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.delete(baseUrl + 'purchasedProducts/' + purchaseProductId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}


export const deleteFile = async (soldProductId, filename) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.delete(baseUrl + 'soldProducts/materialFiles/' + soldProductId + '/' + filename, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}

export const publishproduct = async (productId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.get(baseUrl + 'products/publish/' + productId, {
    headers:
      { authorization: `Bearer ${token}` }
  })
    .then(res => res.data);
}