/**
 *  header-menu and sidebar menu data
 */

/* eslint-disable */
export default [
   {
      "menu_title": "menu.Home",
      "path": "/",
      "icon": "home",
      "child_routes": null
   },
   {
      "menu_title": "menu.shop",
      "path": "/shop",
      "icon": "pages",
      "child_routes":null
   },
   {
      "menu_title": "menu.contact us",
      "path": "/contact-us",
      "icon": "perm_contact_calendar",
      "child_routes": null
   }
]
