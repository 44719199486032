/**
 * logout header widget
*/
/* eslint-disable */
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from  '../../../actions/authActions';
// intl messages
import IntlMessages from '../../../util/IntlMessages';
import {injectIntl} from 'react-intl';


class Logout extends React.Component {
   state = {
      anchorEl: null,
      user: {
         //url: require('../../../assets/avatars/user-4.jpg'),
         alt: 'user'
      },
      menus: [
         {
            id: 1,
            title: <IntlMessages id="account.menu.profile"/>,
            icon: 'account_circle',
            path: '/account/profile'
         },
        //  {
        //     id: 2,
        //     title: 'Account',
        //     icon: 'settings',
        //     path: '/account/profile'
        //  },
        //  {
        //     id: 3,
        //     title: 'Message',
        //     icon: 'email',
        //     path: '/account/profile'
        //  },
         {
            id: 4,
            title: this.props.intl.formatMessage({ id: "account.menu.logout"}),
            icon: 'power_settings_new',
            path: '/sign-in'
         }
      ]
   };

   //define function for open dropdown
   handleMenu = event => {
      this.setState({ anchorEl: event.currentTarget });
   };

   //define function for close dropdown
   handleClose = () => {
      this.setState({ anchorEl: null });
   };

   handleClick = (title) => {
	   title == this.props.intl.formatMessage({ id: "account.menu.logout"}) ? (this.handleClose(),  this.props.signOut()) : this.handleClose();
   };

   render() {
	  const { anchorEl } = this.state;
	  const { profile, basicProfile } = this.props;
      const open = Boolean(anchorEl);
      const user = this.state.user;
      return (
         <div className='iron-logout-wrap'>
            <Avatar
               aria-owns={open ? 'menu-appbar' : null}
               aria-haspopup="true"
               onClick={this.handleMenu}
               className="icon-btn"
               alt={user.alt} src={profile.imageAvatar != undefined ? profile.imageAvatar : basicProfile.photoURL}
            >
            </Avatar>
            <Menu
               anchorEl={anchorEl}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               open={open}
               onClose={this.handleClose}
               className="iron-dropdown"
            >
               {this.state.menus.map((menu, index) => (
                  <MenuItem
                     key={index}
                     onClick={() => this.handleClick(menu.title)}
                     to={menu.path}
                     component={Link}
                  >
                     <i className="material-icons">{menu.icon}</i>
                     <span className="mb-0">{menu.title}</span>
                  </MenuItem>
               ))}
            </Menu>
         </div>
      );
   }
}

const mapStateToprops = (state) => {
	return{
		basicProfile: state.firebase.auth,
		profile: state.firebase.profile
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut())
	}
}

export default injectIntl(connect(mapStateToprops, mapDispatchToProps)(Logout));