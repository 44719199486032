import firebase from '../firebase';
import axios from 'axios';
import appConfig from '../constants/AppConfig'
const baseUrl = appConfig.backEndBaseURL;

export const updateLastLogin = async (user) => {

	const token = await firebase.auth().currentUser.getIdToken();
	console.log(user);
	return axios.put(baseUrl + 'users/updateLastLogin', user, {
	  headers: { authorization: `Bearer ${token}` },
	  "Content-Type": "multipart/form-data",
	})
	  .then(res => res.data);
  }

export const update_user = async (user) => {

  const token = await firebase.auth().currentUser.getIdToken();

  return axios.put(baseUrl + 'users/update', user, {
    headers: { authorization: `Bearer ${token}` },
    "Content-Type": "multipart/form-data",
  })
    .then(res => res.data);
}

export const addUserToMailing = async (user) => {

  return axios.post(baseUrl + 'users/addToMailing', user)
    .then(res => res.data);
}

export const desvinculatePaymentAccount = async (mpUserId) => {
  const token = await firebase.auth().currentUser.getIdToken();

  return axios.post(baseUrl + 'mlWebhooks/desvinculateAccount', 
    {
	  appId: process.env.REACT_APP_MERCADOPAGO_APP_ID,
	  mpUserId: mpUserId 
	},  
    {
      headers: { authorization: `Bearer ${token}` }
    })
      .then(res => res)
      .catch(error => error);

}