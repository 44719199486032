/**
 * Content loader
 */
/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const TransparentContentLoader = (props) => {
	
	const {loading} = props;

	if (!loading) { return null };

	return(
		<div className="iron-transp-progress-bar d-flex justify-content-center align-items-center">
			<CircularProgress size={70} thickness={1.5} />
		</div>
	)
};

const mapStateToprops = ({ appSettings }) => {
	return{
		loading: appSettings.loading
	}
}

export default connect(mapStateToprops)(TransparentContentLoader)