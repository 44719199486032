/**
 * reducer 
 */
import { combineReducers } from 'redux';
import ecommerceReducer from './ecommerceReducer';
import appSettings from './appSettings';
import authReducer from './authReducer';
import {firebaseReducer} from 'react-redux-firebase';

const reducers = combineReducers({
   ecommerce: ecommerceReducer,
   auth: authReducer,
   firebase: firebaseReducer,  
   appSettings
})

export default reducers;