import {
	REDIRECT_TO,
	NULL_REDIRECT,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
 	SIGNOUT_SUCCESS,
 	SIGNUP_SUCCESS,
  	SIGNUP_ERROR,
  	SEND_MAIL_RESET_SUCCESS, 
  	SEND_MAIL_RESET_ERROR, 
  	UPLOAD_FILE_SUCCESS, 
  	UPLOAD_FILE_ERROR, 
	DELETE_FILE_SUCCESS,
	DELETE_FILE_ERROR,     
	UPDATE_PROFILE_SUCCESS, 
	UPDATE_PROFILE_ERROR
 } from '../actions/types';

const initialState = {
	authError: null 
}

//Change Descriptions

const authReducer = (state = initialState, action) => {
	switch(action.type){
		case REDIRECT_TO:
			console.log('Redirect Success')
			return{
				...state, 
				redirectTo: action.payload
			}
		case NULL_REDIRECT:
			console.log('No Redirect')
			return{
				...state, 
				redirectTo: null
			}	
		case LOGIN_SUCCESS:
			console.log('Login Success')
			return{
				...state, 
				authError: null
			}
		case LOGIN_ERROR:
			console.log('Login Failed')
			return{
				...state,
				authError: 'Login Failed'
			}
		case SIGNOUT_SUCCESS:
			console.log('Sign out Success')
			return{
				...state,
				authError: null
			}
		case SIGNUP_SUCCESS:
			console.log('Sign up Success')
			return{
				...state,
				authError: null
			}
		case SIGNUP_ERROR:
			console.log('Sign up Failed')
			return{
				...state,
				authError: action.error.message
			}
		case SEND_MAIL_RESET_SUCCESS:
			console.log('Send Email Reset Password Success')
			return{
				...state,
				authError: null
			}
		case SEND_MAIL_RESET_ERROR:
			console.log('Send Email Reset Password Error')
			return{
				...state,
				authError: action.error.message
			}
		case UPLOAD_FILE_SUCCESS:
			console.log('Upload File Success')
			return{
				...state,
				authError: null
			}
		case UPLOAD_FILE_ERROR:
			console.log('Upload File Error')
			return{
				...state,
				authError: action.error.message
			}
		case DELETE_FILE_SUCCESS:
			console.log('Delete File Success')
			return{
				...state,
				authError: null
			}
		case DELETE_FILE_ERROR:
			console.log('Delete File Error')
			return{
				...state,
				authError: action.error.message, 
				showAlert: true,
            	alertMessage: 'Error, try again later',
            	alertType: 'error'
			}			
		case UPDATE_PROFILE_SUCCESS:
			console.log('Update Profile Success')
			return{
				...state,
				authError: null
			}
		case UPDATE_PROFILE_ERROR:
			console.log('Update Profile Error')
			return{
				...state,
				authError: action.error.message
			}								
		default:
			return state;	
	}	
}

export default authReducer