/**
 * action types
 * TODO: ADD ALL AUTHORIZATION TYPES
 */
//Ecommerce types
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const MOVE_WISHLISTITEM_TO_CART = 'MOVE_WISHLISTITEM_TO_CART';
export const FINAL_PAYMENT = 'FINAL_PAYMENT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const RTL_LAYOUT = "RTL_LAYOUT";
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DELETE_USER = 'DELETE_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const DARK_MODE = 'DARK_MODE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
//Products types
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
//Ratings types
export const ADD_RATING_SUCCESS = 'ADD_RATING_SUCCESS';
//Authorization types
export const REDIRECT_TO = 'REDIRECT_TO';
export const NULL_REDIRECT = 'NULL_REDIRECT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SEND_MAIL_RESET_SUCCESS = 'SEND_MAIL_RESET_SUCCESS';
export const SEND_MAIL_RESET_ERROR = 'SEND_MAIL_RESET_ERROR';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';   
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
