import { addProduct, 
	updateproduct, 
	deleteproduct, 
	publishproduct, 
	addrating, 
	updatesoldProduct, 
	deleteSoldProduct, 
	deletePurchaseProduct } from '../services/productService'

/**
 * Action Types
 */
import {
	COLLAPSED_SIDEBAR,
	RTL_LAYOUT,
	ADD_TO_CART,
	ADD_TO_WISHLIST,
	SHOW_ALERT,
	HIDE_ALERT,
	REMOVE_FROM_CART,
	UPDATE_PRODUCT_QUANTITY,
	CHANGE_CURRENCY,
	SET_LANGUAGE,
	FINAL_PAYMENT,
	MOVE_WISHLISTITEM_TO_CART,
	REMOVE_FROM_WISHLIST,
	DELETE_USER,
	ADD_NEW_USER,
	DARK_MODE,
	ADD_PRODUCT_SUCCESS,
	//ADD_PRODUCT_ERROR,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_ERROR,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_ERROR,
	ADD_RATING_SUCCESS,
	//UPLOAD_FILE_SUCCESS, 
	//UPLOAD_FILE_ERROR,   
	SHOW_LOADING,
	HIDE_LOADING, 
	REDIRECT_TO
} from './types';
import { analytics } from '../firebase';

import React  from 'react';

// intl messages
import IntlMessages from '../util/IntlMessages';

//add product item
export const addProductItem = (data, alertMessage) => (dispatch) => {
   dispatch({
      type: ADD_TO_CART,
      payload: data
   })
}

//show alert box
export const showAlert = (message, type) => ({
   type: SHOW_ALERT,
   payload: { message, type }
})

//hide alert box
export const hideAlert = () => ({
   type: HIDE_ALERT,
})

//show loader
export const showLoader = () => (dispatch) => {
	dispatch({
		type: SHOW_LOADING,
	})	
}
 
 //hide loader
 export const hideLoader = () => ({
	type: HIDE_LOADING,
 })

// add product to wishlist
export const addToWishlist = (data, alertMessage) => (dispatch) => {
   dispatch({
      type: ADD_TO_WISHLIST,
      payload: data
   })
}

//move all wishlist item to cart
export const moveWishlistItemToCart = () => ({
   type: MOVE_WISHLISTITEM_TO_CART
})

//delete wishlist item 
export const deleteItemFromWishlist = (data) => ({
   type: REMOVE_FROM_WISHLIST,
   payload: data
})

//Remove product item
export const removeProductItem = (data) => ({
   type: REMOVE_FROM_CART,
   payload: data
})

//update product quantity
export const updateProductQuantity = (data) => ({
   type: UPDATE_PRODUCT_QUANTITY,
   payload: data
})

// change currency
export const changeCurrency = (currency) => ({
   type: CHANGE_CURRENCY,
   payload: currency
})

// change language
export const setLanguage = (locale) => ({
   type: SET_LANGUAGE,
   payload: locale
})

//final payment 
export const finalPayment = (history) => (dispatch) => {
   history.push('/final-receipt');
   dispatch({ type: FINAL_PAYMENT });

}

// Rtl Layout
export const rtlLayoutAction = (isRtlLayout) => ({
   type: RTL_LAYOUT,
   payload: isRtlLayout
});

//Redux Action To Emit Collapse Sidebar
export const collapsedSidebarAction = (isCollapsed) => ({
   type: COLLAPSED_SIDEBAR,
   isCollapsed
});

//======== Admin-panel actions ========

//add user
export const addNewUser = (data) => ({
   type: ADD_NEW_USER,
   payload: data
})

//delete user 
export const deleteUser = (data) => ({
   type: DELETE_USER,
   payload: data
})

//======== Customiser ========
/**
 * Redux Action To Emit Dark Mode
 * @param {*boolean} isDarkMode 
 */
export const darkModeAction = (isDarkMode) => ({
	type: DARK_MODE,
	payload: isDarkMode
});

//======== Products/Items actions ========
// add product/item
export const addNewProduct = (item) => {
	return(dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();
		var user = firebase.auth().currentUser;
		//firebase.profile.profile.imageAvatar
		item['imageAvatar'] = user.photoURL;
		item['userId'] = user.uid;
		item['userEmail'] = user.email;

		addProduct(item).then(res => {
			analytics.logEvent('new_product', {category: item.type});
			dispatch({ type: ADD_PRODUCT_SUCCESS });
			if (item.draft){
			  analytics.logEvent('new_product', {category: item.type, published: true});
			  dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.addProduct.draft.success"/>, type: 'success' }});
			}
			else {
				analytics.logEvent('new_product', {category: item.type, published: false});	
			  dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.addProduct.publish.success"/>, type: 'success' }});
			}
			dispatch({type: REDIRECT_TO, payload:'/admin-panel/admin/products'});
		})
	}
}

export const updateProduct = (item) => {
	return(dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();
		var user = firebase.auth().currentUser;
		//firebase.profile.profile.imageAvatar
		item['imageAvatar'] = user.photoURL;
		item['userId'] = user.uid;

		updateproduct(item).then(res => {
			dispatch({ type: UPDATE_PRODUCT_SUCCESS });
			if (item.draft){
			  analytics.logEvent('updated_product', {category: item.type, published: true});	
			  dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateProduct.draft.success"/>, type: 'success' }});
			} else{
			  analytics.logEvent('updated_product', {category: item.type, published: false});		
			  dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateProduct.publish.success"/>, type: 'success' }});
			}
			dispatch({type: REDIRECT_TO, payload:'/admin-panel/admin/products'});
		}).catch((error) => {
			dispatch({type: UPDATE_PRODUCT_ERROR, error});
			dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateProduct.error"/>, type: 'error' }});
		});
	}
}

export const updateSoldProduct = (id, item) => {
	return(dispatch, getState) => {
		
		updatesoldProduct(id, item).then(res => {
			dispatch({ type: UPDATE_PRODUCT_SUCCESS });
			dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateSoldProduct.success"/>, type: 'success' }});
		})
	}
}

export const deleteProduct = (deleteItemID) => {
	return async (dispatch, getState) => {

		deleteproduct(deleteItemID)
			.then(() => {		
				analytics.logEvent('updated_product', {published: true});	
				dispatch({ type: DELETE_PRODUCT_SUCCESS });
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.deleteProduct.success"/>, type: 'success' }});
			}).catch((error) => {
				dispatch({type: DELETE_PRODUCT_ERROR, error});
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.deleteProduct.error"/>, type: 'error' }});
			});
	}
}

export const publishProduct = (productId) => {
	return async (dispatch, getState) => {

		publishproduct(productId)
			.then(() => {		
				dispatch({ type: ADD_PRODUCT_SUCCESS });
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.addProduct.publish.success"/>, type: 'success' }});
			}).catch((error) => {
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.addProduct.error"/>, type: 'error' }});
			});
	}
}

//======== Ratings actions ========
// add new rating.
export const addRating = (item) => {
	return(dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();
		var user = firebase.auth().currentUser;
		//firebase.profile.profile.imageAvatar
		item['imageAvatar'] = user.photoURL;
		item['userId'] = user.uid;

		addrating(item).then(res => {
			dispatch({ type: ADD_RATING_SUCCESS });
			dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.rating.success"/>, type: 'success' }});
			dispatch({type: REDIRECT_TO, payload:'/admin-panel/admin/products'});
		})
	}
}
export const deleteSProduct = (deleteItemID) => {
	return async (dispatch, getState) => {

		deleteSoldProduct(deleteItemID)
			.then(() => {				
				dispatch({ type: DELETE_PRODUCT_SUCCESS });
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.deleteProduct.success"/>, type: 'success' }});
			}).catch((error) => {
				dispatch({type: DELETE_PRODUCT_ERROR, error});
			});
	}
}

export const deletePurchase = (deleteItemID) => {
	return async (dispatch, getState) => {

		deletePurchaseProduct(deleteItemID)
			.then(() => {				
				dispatch({ type: DELETE_PRODUCT_SUCCESS });
				dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.deleteProduct.success"/>, type: 'success' }});
			}).catch((error) => {
				dispatch({type: DELETE_PRODUCT_ERROR, error});
			});
	}
}
