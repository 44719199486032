/**
 * Dasboard Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// import helmet
import { Helmet } from "react-helmet";

// async components
import {
   AsyncPurchasedProductsGridComponent,
   AsyncProductPurchasedDetailsComponent,
   AsyncPageNotFoundComponent
} from '../../util/AsyncRoutes';

// private Routes helper
import PrivateRoute from './../../util/PrivateRoutes';

const StudPanel = ({ match }) => {
   return (
      <div className="dashboard-wrapper">
		<Helmet>
			<title>Painel do Estudante - Todas Aulas</title>
		</Helmet>
         <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/purchasedProducts`} />
            <PrivateRoute path={`${match.url}/purchasedProducts`} component={AsyncPurchasedProductsGridComponent} />
            <PrivateRoute path={`${match.url}/productPurchasedDetails/:id`} component={AsyncProductPurchasedDetailsComponent} />
			<Route path={`${match.url}/*`} component={AsyncPageNotFoundComponent} />
         </Switch>
      </div>
   )
}

export default StudPanel;