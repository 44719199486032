/**
 * Sidebar menu component
 */
import React, { Fragment } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import { List, Button } from '@material-ui/core';
// connect to store
import { connect } from 'react-redux';
// intl messages
import IntlMessages from '../../../util/IntlMessages';

//data
import navLinks from '../../../assets/data/NavLinks.js';

//component
import MenuListItem from './MenuListItem';

class SidebarMenu extends React.Component {

   state = {
      open: false
   };

   /**
    * function for toggle sidebar drawer
    */
   toggleDrawer() {
      this.setState({
         open: !this.state.open
      });
   };

   /**
    * function for close sidebar drawer
    */
   closeDrawer() {
      this.setState({
         open: false
      });
   }

   render() {

		const {authentication} = this.props;

      return (
         <div className="py-10 text-left iron-sidebar-nav-wrap">
			 {/* <div className="d-flex justify-content-between"> */}
            <IconButton onClick={this.toggleDrawer.bind(this)} className="nav-menu-icon">
			   	<i className="material-icons">menu</i>
            </IconButton>
			{/*<Button href="/sign-up" className="text-padding-left button btn-outlined btn-sm">
					Sign Up
				</Button>
			</div>*/}
            <SwipeableDrawer
               open={this.state.open}
               onClose={this.toggleDrawer.bind(this)}
               onOpen={this.toggleDrawer.bind(this)}
               className="sidebar-area"
            >
               <Scrollbars
                  autoHide
                  autoHideDuration={100}
                  style={{ width: 280, height: 'calc(100vh - 2rem)' }}
               >
                  <div className="vertical-menu py-40">
                     <List component="nav" className="iron-sidebar-menu">
                        {navLinks.map((NavLink, index) => (
                           <MenuListItem
                              menu={NavLink}
                              key={index}
                              closeDrawer={() => this.closeDrawer()}
                           />
                        ))}
						{((authentication.uid)) 
						? (
							<Fragment>
								<div className="py-10 text-center">
									<Button href="/admin-panel/admin" className="button btn-outlined btn-lg">
										<IntlMessages id="menu.teachPanel"/>
									</Button>
								</div>
								<div className="py-10 text-center">
									<Button href="/admin-panel/stud" className="button btn-outlined btn-lg">
										<IntlMessages id="menu.studPanel"/>
									</Button>
								</div>
							</Fragment>	
						) : (
							<Fragment>
								<div className="py-10 text-center">
									<Button href="/sign-up" className="button btn-active btn-lg">
										<IntlMessages id="signUp"/>
									</Button>
								</div>
								<div className="py-10 text-center">
									<Button href="/sign-in" className="button btn-outlined btn-lg">
										<IntlMessages id="signIn"/>
									</Button>
								</div>
							</Fragment>
						)}
                     </List> 
                  </div>
               </Scrollbars>
            </SwipeableDrawer>
         </div>
      );
   }
}

function mapStateToProps(state) {
	return {
	  authentication: state.firebase.auth,
	};
}

export default connect(mapStateToProps)(SidebarMenu);


