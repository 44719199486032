/**
 *  admin sidebar menu
 */
/* eslint-disable */
export default [
  {
    menu_title: "admin.products",
    path: "#",
    icon: "shopping_cart",
    isMenuOpen: "true",
    child_routes: [
      {
        path: "/admin-panel/stud/purchasedProducts",
        menu_title: "admin.purchasedProducts",
        icon: "arrow_right_alt",
      },
    ],
  },
  {
    menu_title: "admin.goToHome",
    path: "/",
    icon: "home",
    child_routes: null,
  },
];
