/**
 * App Language Provider
 * Add more locales here
 */

import enLang from './entries/en-US';
import frLang from './entries/fr_FR';
import ptLang from './entries/pt-BR';

const AppLocale = {
    en: enLang,
	fr: frLang,
	pt: ptLang
};

export default AppLocale;
