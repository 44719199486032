/**
 * Action Types
 */
import {
	SHOW_ALERT,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
 	SIGNOUT_SUCCESS,
 	SIGNUP_SUCCESS,
  	SIGNUP_ERROR,
  	SEND_MAIL_RESET_SUCCESS, 
  	SEND_MAIL_RESET_ERROR, 
  	UPLOAD_FILE_SUCCESS, 
  	UPLOAD_FILE_ERROR, 
  	UPDATE_PROFILE_SUCCESS, 
	UPDATE_PROFILE_ERROR,
	//SHOW_LOADING,
	HIDE_LOADING,
	REDIRECT_TO,
	NULL_REDIRECT   
} from './types';	
import { analytics } from '../firebase';

import React  from 'react';
import { resizeFile, generateInitialsAvatar } from '../helpers';
// intl messages
import IntlMessages from '../util/IntlMessages';
// eslint-disable-next-line
import { addUserToMailing, update_user, updateLastLogin } from '../services/userService';

export const redirect = (link) => ({
		type: REDIRECT_TO,
		payload: link
})

export const nullRedirect = () => ({
	type: NULL_REDIRECT,
})


export const signIn = (credentials, hasToRedirect = true) => {
	return (dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();
		const user = {email:credentials.emailAddress}
		firebase.auth().signInWithEmailAndPassword(
			credentials.emailAddress,
			credentials.password
		).then(() => {
			analytics.logEvent('login', {
				method: 'password'
			})
			dispatch({ type: LOGIN_SUCCESS });
			console.log(user);
			updateLastLogin(user); //Update last login on mail marketing
			if (hasToRedirect) 
				dispatch(redirect('/'));
		}).catch((error) => {
			dispatch({type: LOGIN_ERROR, error});
		})

	}
}

export const signOut = () => {
	return(dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();

		firebase.auth().signOut().then(() => {
			dispatch ({ type: SIGNOUT_SUCCESS });
			dispatch(redirect('/sign-in'));
		});
	}
}


export const ResetPassByEmail = (emailAddress) => {
	return (dispatch, getState, {getFirebase}) => {
		const firebase = getFirebase();
	
		firebase.auth().sendPasswordResetEmail(
			emailAddress
		).then(() => {
			dispatch({ type: SEND_MAIL_RESET_SUCCESS });
		}).catch((error) => {
			dispatch({type: SEND_MAIL_RESET_ERROR, error});
		})
	}
}

//This function updates user.imageAvatar and profile.photoURL
export const updateAvatar = (photo) => {
	return async (dispatch, getState, {getFirebase, getFirestore}) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		const firestore = getFirestore();

		var user = firebase.auth().currentUser;
		
		var resizedPhoto = 	await resizeFile(photo);
		
		if(Boolean(user)) {

			const uploadTask = storage.ref(`/images/avatar/${user.uid}`).put(resizedPhoto)

			uploadTask.on('state_changed', (snapShot) => {
				//takes a snap shot of the process as it is happening
				dispatch({ type: UPLOAD_FILE_SUCCESS });
			}, (error) => {
					//catches the errors
					dispatch({type: UPLOAD_FILE_ERROR, error});
			}, () => {
				// gets the functions from storage refences the image storage in firebase by the children
				// gets the download url then sets the image from firebase as the value for the imgUrl key:
				storage.ref('images/avatar/').child(user.uid).getDownloadURL()
				.then(fireBaseUrl => {
					var user = firebase.auth().currentUser;
					//const test = {a:2, b:3};  
					// update basic profile of firebase
					user.updateProfile({
						photoURL: fireBaseUrl
					}).then(() => {
						dispatch({ type: UPDATE_PROFILE_SUCCESS });
						// update users table imageAvatar field
						firestore.collection('users').doc(user.uid).update({
							imageAvatar: fireBaseUrl,
						}).then(() => {
							dispatch({ type: UPDATE_PROFILE_SUCCESS });
							dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateAvatar.success"/>, type: 'success' }});
						}).catch((error) => {
							dispatch({type: UPDATE_PROFILE_ERROR, error});
						}); 
					}).catch((error) => {
						dispatch({type: UPDATE_PROFILE_ERROR, error});
					});
				})
			
			})
		} else{
			//Alert: Login, please
		}	
	}
}

//This function update only firebase profile.photoURL
// eslint-disable-next-line
function simpleUpdtAvatarOnPhURL(photo) {
	return async(dispatch, {getFirebase}) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		
		var user = firebase.auth().currentUser;

		if(Boolean(user)) {
			const uploadTask = storage.ref(`/images/avatar/${user.uid}`).put(photo)
				
			uploadTask.on('state_changed', (snapShot) => {
				//takes a snap shot of the process as it is happening
				dispatch({ type: UPLOAD_FILE_SUCCESS });
			}, (error) => {
					//catches the errors
					dispatch({type: UPLOAD_FILE_ERROR, error});
			}, () => {
				// gets the functions from storage refences the image storage in firebase by the children
				// gets the download url then sets the image from firebase as the value for the imgUrl key:
				storage.ref('images/avatar/').child(user.uid).getDownloadURL()
				.then(async fireBaseUrl => {
					// update basic profile of firebase
					await user.updateProfile({
						photoURL: fireBaseUrl
					}).then(() => {
						dispatch({ type: UPDATE_PROFILE_SUCCESS });
					}).catch((error) => {
						dispatch({type: UPDATE_PROFILE_ERROR, error});
					});
				})
			
			})
		} else{
			//Alert: Login, please
		}	
	}
}

export const signUp = (newUser) => {
	return async (dispatch, getState, {getFirebase, getFirestore}) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		const storage = firebase.storage();
	
		firebase.auth().createUserWithEmailAndPassword(
			newUser.emailAddress,
			newUser.password
		).then((response) => {
			return firestore.collection('users').doc(response.user.uid).set({
				firstName: newUser.firstName,
				lastName: newUser.lastName,
				email: newUser.emailAddress
			}).then(async (value) => {
				analytics.logEvent('sign_up', {
					method: 'password'
				})
				
				if (typeof window !== "undefined") {
					if (window.fbq != null) { 
					  window.fbq('track', 'CompleteRegistration');
					}
				 }
				
				var user = firebase.auth().currentUser;
				await user.updateProfile({displayName: newUser.firstName})
				var initials = (newUser.firstName.trim().charAt(0) + newUser.lastName.trim().charAt(0)).toUpperCase();
				
				var photo = await generateInitialsAvatar(initials);
				
				//simpleUpdtAvatarOnPhURL('image');
				const uploadTask = storage.ref(`/images/avatar/${user.uid}`).put(photo)
				
				uploadTask.on('state_changed', (snapShot) => {
					//takes a snap shot of the process as it is happening
					dispatch({ type: UPLOAD_FILE_SUCCESS });
				}, (error) => {
						//catches the errors
						dispatch({type: UPLOAD_FILE_ERROR, error});
				}, () => {
					// gets the functions from storage refences the image storage in firebase by the children
					// gets the download url then sets the image from firebase as the value for the imgUrl key:
					storage.ref('images/avatar/').child(user.uid).getDownloadURL()
					.then(async fireBaseUrl => {
						// update basic profile of firebase
						await user.updateProfile({
							photoURL: fireBaseUrl
						}).then(async () => {
							dispatch({ type: UPDATE_PROFILE_SUCCESS });
							await firestore.collection('users').doc(user.uid).update({
								imageAvatar: fireBaseUrl,
							}).then(() => {
								dispatch({ type: UPDATE_PROFILE_SUCCESS });
							}).catch((error) => {
								dispatch({type: UPDATE_PROFILE_ERROR, error});
							});
						}).catch((error) => {
							dispatch({type: UPDATE_PROFILE_ERROR, error});
						});
					})
				
				})
				//send usr to mailing
				var userToMailing = { firstName: newUser.firstName, lastName: newUser.lastName, email: newUser.emailAddress };
				    addUserToMailing(userToMailing)
				 	.then(function(response) {
				 		console.log(response);
				})
				dispatch({ type: SIGNUP_SUCCESS })
				dispatch(redirect('/account/profile?firstTime=true'));
				dispatch({type: HIDE_LOADING})	
			}).catch(error => {
				console.log(error.message)
				dispatch({ type: SIGNUP_ERROR, error })
				dispatch({type: HIDE_LOADING})
			})
		}).catch(error => {
			dispatch({ type: SIGNUP_ERROR, error })
			dispatch({type: HIDE_LOADING})
		})
	}
} 

export const updateUser = (updatedProfile) => {
	return(dispatch, getState) => {
		update_user(updatedProfile)
		.then(() => {
			dispatch(redirect('/account/profile'));			
			dispatch({ type: UPDATE_PROFILE_SUCCESS });
			dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateData.success"/>, type: 'success' }});
		}).catch((error) => {
			dispatch({type: UPDATE_PROFILE_ERROR, error});
			dispatch({type: SHOW_ALERT, payload: { message: <IntlMessages id="alert.updateData.error"/>, type: 'error' }});
		});
	}
}
