module.exports = {
   "menu.adminPanel": "Painel Administrativo",
   "menu.teachPanel": "Painel do Professor",
   "menu.studPanel": "Painel do Estudante",
   "menu.Home": "Início",
   "menu.HomeOne": "Home 1",
   "menu.HomeTwo": "Home 2",
   "menu.HomeThree": "Home 3",
   "menu.fashion": "Moda",
   "menu.shop": "Loja",
   "menu.gadgets": "Gadgets",
   "menu.accessories": "Acessórios",
   "menu.categories": "Categorias",
   "menu.pages": "Páginas",
   "menu.session": "Seção",
   "menu.contact us": "Fale Conosco",
   "menu.men": "Homem",
   "menu.women": "Mulher",
   "menu.t-shirts": "Camiseta",
   "menu.jeans": "Jeans",
   "menu.shoes": "Sapatos",
   "menu.wallet": "Wallet",
   "menu.westernWear": "western wear",
   "menu.ethnicWear": "ethnic wear",
   "menu.sportsWear": "sports wear",
   "menu.headPhones": "headPhones",
   "menu.laptop": "Notebook",
   "menu.speaker": "speaker",
   "menu.watch": "Assistir",
   "menu.jewellery": "jewellery",
   "menu.belts": "belts",
   "menu.handBag": "handBag",
   "menu.productDetail": "Detalhe da Aula",
   "menu.cart": "Carrinho de Compras",
   "menu.checkout": "Checkout",
   "menu.payment": "Pagamento",
   "menu.blogDetail": "Detalhe do Blog",
   "menu.userProfile": "Perfil do Usuário",
   "menu.faq": "Perguntas Frequentes",
   "menu.404Page": "404 page",
   "menu.signIn": "Entrar",
   "menu.register": "Quero me Cadastrar",
   "menu.forgotPassword": "Esqueci minha senha",
   "menu.thankYou": "Obrigado",
   "menu.aboutUs": "Quem Somos",
   "menu.termsAndConditions": "Termos e Condições",
   "menu.privacyPolicy": "Política de Privacidade",
   "home.howWorks":"Como Funciona",
   "home.studyAreas":"Áreas de Estudo Destacadas",
   "home.customerReviews":"Avaliações de Clientes",
   "account.menu.classes":"Aulas",  
   "account.menu.profile":"Perfil",
   "account.menu.address":"Endereço",
   "account.menu.cards":"Cartões Salvos",
   "account.menu.logout":"Sair",
   "account.hi":"Olá, ",
   "account.completeProfile":"! Por favor, complete seu perfil para uma melhor experiência.",  
   "account.profile.info":"Informação do Perfil",
   "account.profile.email.info":"Atenção: Verifique sua <b>caixa de e-mail</b> e <b>confirme seu cadastro</b> para receber as notificações importantes sobre suas aulas e conta. Se o e-mail foi para sua caixa de <b>spam</b>, por favor <b>salve nosso contato como confiável para não perder as atualizações</b>",
   "account.fname":"Nome:",
   "account.lname":"Sobrenome:",
   "account.email":"E-mail:",  
   "account.gender":"Sexo",
   "account.male":"Masculino",
   "account.female":"Feminino",
   "account.undefined":"Não informado",
   "account.birthDate":"Data de Aniversário",
   "account.mobile":"Celular",
   "account.edit.edit":"Editar Informação de Perfil",
   "account.edit.fnameReq":"Nome *",
   "account.edit.lnameReq":"Sobrenome *",
   "account.edit.emailReq":"E-mail *",
   "footer.whyWeDo": "Por que fazemos o que fazemos?", 
   "footer.usefulLinks": "Links úteis", 
   "footer.socialNetworks": "Redes Sociais",
   "footer.home": "Início",
   "footer.shop": "Loja",
   "footer.termsAndCondition": "Termos e Condições",
   "footer.PrivacyPolicy": "Política de Privacidade",
   "footer.Faq": "Perguntas Frequentes",
   "footer.contactUs": "Fale Conosco",
   "footer.signIn": "Entrar",
   "footer.register": "Quero me Cadastrar",
   "footer.forgetPassword": "Esqueci minha senha",
   "footer.thankYou": "Obrigado",
   "footer.women": "Mulher",
   "footer.men": "Homem",
   "footer.accessories": "accessories",
   "footer.gadgets": "Gadgets",
   "admin.important":"Importante",
   "admin.reports": "Relatórios",
   "admin.invoices": "Faturamento",
   "admin.products": "Minhas Aulas",
   "admin.createdProducts": "Aulas Criadas",
   "admin.publishedProducts": "Aulas Publicadas",
   "admin.profile": "Perfil",
   "admin.productAdd": "Adicionar Aula",
   "admin.purchasedProducts": "Aulas compradas",
   "admin.soldProducts": "Aulas vendidas",
   "admin.goToHome": "Voltar para Início",
   "admin.myFinances": "Minhas Finanças",
   "admin.receiveMoneyData": "Meu pagamento",
   "admin.products.grid": "Aulas em Grade",
   "admin.products.list": "Aulas em Lista",
   "admin.products.actions": "Ações",
   "admin.products.first": "Início",
   "admin.products.previous": "Anterior",
   "admin.products.next": "Próximo",
   "admin.products.last": "Final",
   "admin.products.loading": "Carregando...",
   "admin.products.noData": "Aula não encontrada",
   "admin.products.page": "Página",
   "admin.products.of": "de",
   "admin.products.rows": "linhas",
   "admin.addProd.validate.imageReq":"É necessário inserir uma imagem de capa",
   "admin.addProd.validate.cNameReq":"Informe o nome da aula",
   "admin.addProd.validate.priceReq":"Insira o valor da aula",
   "admin.addProd.validate.priceMin":"O valor não pode ser menor que R$50,00",
   "admin.addProd.validate.discMinToPrice":" Escreva o valor promocional da mensalidade",
   "admin.addProd.validate.discPriceInvalid":"O valor não é correto",
   "admin.addProd.validate.noDiscPrice": "ou deixe o campo em branco",
   "admin.addProd.validate.daysReq":"Marque pelo menos um dia da semana",
   "admin.addProd.validate.categoriesReq":"Selecione uma categoria",
   "admin.addProd.validate.timeReq":"Escolha o horário",
   "admin.addProd.validate.levelReq": "Escolha um nível",
   "admin.addProd.validate.durationReq": "Escolha uma duração",
   "admin.addProd.validate.durationMin": "Duração deve ser maior que 0",
   "admin.addProd.validate.classTypeReq":"Escolha um tipo",
   "admin.addProd.validate.modalityReq":"Escolha uma modalidade",
   "admin.addProd.validate.quantityReq":"Escolha a quantidade",
   "admin.addProd.validate.quantityMax":"A quantidade não pode ser maior que 5",
   "admin.addProd.validate.descReq":"Escreva uma descrição",
   "admin.addProd.validate.formError":"Por favor, preencha todos os campos obrigatórios",
   "admin.editProd.validate.noPastDays":"Não pode ser menor a hoje",
   "admin.firstInfo.title":"Importante! Antes de cadastrar  sua primeira aula, veja as seguintes informações:",
   "admin.firstInfo.1":"Antes de cadastrar uma aula e para uma melhor experiência de pesquisa do seu potencial aluno, insira uma foto de perfil ",
   "link.clickHere":"CLICANDO AQUI",
   "admin.firstInfo.1b":"Observação: Escolha uma imagem de capa para sua aula que concorde e seja significativa com o assunto da mesma, isso causará mais impacto visual para os alunos.",
   "admin.firstInfo.2":"Você deve cadastrar uma aula por turma, com seus respectivos dias e horários. Assim você facilitará o método de pesquisa do seu aluno, além de mostrar a quantidade real de vagas disponíveis na turma para cada aula, independente se é o mesmo dia e/ou mesmo horário.",
   "admin.firstInfo.2b":"Por exemplo: Tenho uma mesma aula para oferecer em dias diferentes, mas no mesmo horário, sendo uma de segunda e quarta e a outra de terça e quinta, ambas no turno da noite. Neste caso, é preciso publicar esta aula duas vezes, indicando apenas a diferença dos dias que acontecerão as aulas e mantendo o mesmo horário.",
   "admin.firstInfo.3":"Não é permitido inserir dados pessoais e/ou de contato na descrição ou em qualquer uma das imagens do seu perfil e aulas publicadas. Isto poderá implicar no bloqueio do usuário. ",
   "admin.firstInfo.3b":"Qual é a vantagem de obter contatos dos alunos via plataforma Todas Aulas? O professor aumenta sua popularidade e ganha melhor posicionamento de destaque na plataforma para vender ainda mais, após cada pagamento mensal do aluno.",
   "admin.firstInfo.4":"O preço da aula sempre deverá ser mensal e com valor mínimo de R$50,00 por mês.",
   "admin.firstInfo.4b":"Como funciona o cadastro da minha aula, se ela tem um tempo de duração específico? Se você quer oferecer suas aulas em pacotes, podemos chamá-las de curso. Assim, tendo um curso com um total de duração de 8 aulas, por exemplo, sendo 1 vez por semana cada uma, pode-se dizer que seu curso terminará em 2 meses. Com isso, você deverá cadastrar seu pacote de aulas dividindo o valor total do curso por dois meses e cobrar o respectivo valor mensal. Ou seja, digamos que este pacote com 8 aulas custe R$480,00, você deverá informar na plataforma que a mensalidade é de R$240,00 durante dois meses. Após este período, você deve encerrar a aula na plataforma.",
   "admin.firstInfo.5":"A quantidade máxima de cada turma é de 5 alunos.",
   "admin.firstInfo.6":"Descreva detalhadamente sobre sua aula no campo de descrição da mesma. Você pode escrever sobre você (um mini CV), qual é a duração de uma aula, para quem é indicada, quais conteúdos serão abordados, materiais necessários para participação da aula, etc.",
   "admin.firstInfo.7":"Se você não encontrar a categoria (ou subcategoria) ideal para classificar sua aula, escolha a categoria 'Outros' e solicite acrescentar a sua categoria por email, escrevendo para contato@todasaulas.com. Sendo aprovada, será disponibilizada o mais breve possível.",
   "admin.firstInfo.7b":"Estaremos felizes de inserir uma nova categoria para melhor classificar sua aula e ver como Todas Aulas cresce em novas áreas de estudo.",
   "admin.firstInfo.congrats":"Parabéns, você já pode cadastrar a sua primeira aula!!!",
   "admin.myFinances.link": "Ativar",
   "admin.myFinances.unlink": "Desativar",
   "panels.enter":"Entrar na aula",
   "panels.search":"Pesquisar",
   "panels.stud.cancel":"Cancelar aula",
   "panels.stud.pagar":"Pagar",
   "panels.teach.cancel":"Cancelar turma",  
   "panels.card.nextDayClass": "Próxima aula:",
   "panels.card.startTime": "Hora de início:",
   "panels.card.endTime": "Hora de término:",
   "panels.card.extraInfo": "Informação adicional:",
   "panels.card.extraInfoPH": "Cole aqui informação adicional",
   "panels.card.copy": "Copiar",
   "panels.card.copied": "Copiado!",
   "panels.card.allowSubs": "Receber inscrições",
   "panels.card.allowSubsTt": "Troque para ativar ou desativar novas inscrições",
   "panels.card.active": "Ativo",
   "panels.card.inactive": "Inativo",
   "panels.card.link": "Link da chamada de vídeo:", //Link de acesso?
   "panels.card.linkPH": "Cole aquí o link de acesso",
   "panels.card.invalidURL": "Link inválido",
   "panels.materials": "Materiais de Aula",
   "panels.materials.file": "Arquivo",
   "panels.materials.download": "Download",
   "panels.materials.downloadTt": "Fazer download",
   "panels.materials.delete": "Excluir",
   "panels.materials.deleteTt": "Excluir arquivo",
   "panels.materials.upload.success": "Arquivo carregado com sucesso",
   "panels.materials.upload.error": "Não foi possível fazer upload",
   "panels.materials.upload.addNew": "+ Inserir",
   "panels.gang": "Alunos",
   "panels.gang.lastPayment": "Ultimo pagamento",
   "panels.gang.deleteTt": "Para excluir um aluno entre em contato com o suporte",
   "panels.noRows": "Ops! Não há nada para mostrar aqui.",
   "signUp":"Quero me Cadastrar",
   "signUp.enterDetails":"Preencha seus dados abaixo:",
   "signUp.clear":"limpar",
   "signUp.alreadyHaveAccount":"Você já tem uma conta? Clique aqui: ",
   "signUpAs.button.signUp":"Cadastre suas aulas",
   "signUpAs.button.signUpNow":"Quero me Cadastrar Agora",
   "signUpAs.titleRegister":"Seja parte de Todas Aulas!",
   "signUpAs.subTitleRegister":"Preencha abaixo e crie sua conta de Professor:",
   "signUpAs.sales1.line1":" Venda Mais",
   "signUpAs.sales1.line2":" 24h por dia",
   "signUpAs.sales1.line3":" todos os dias!",
   "signUpAs.sales2.line1":" Ganhe",
   "signUpAs.sales2.line2":" 70%",
   "signUpAs.sales2.line3":" do valor das aulas na hora!",
   "signUpAs.sales3.line1":" Ao Vivo",
   "signUpAs.sales3.line2":" Converse em tempo real",
   "signUpAs.sales3.line3":" com seus alunos",
   "signUpAs.teachReviews":"Depoimentos de Professores",
   "signIn":"Entrar",
   "sinIn.enterDetails": "Acesso do Usuário",
   "signIn.rememberMe": "Lembrar-me",
   "signIn.forgotPass": "Esqueceu sua senha?",
   "signIn.noAccount": "Você não tem uma conta? ",
   "signIn.createOne": "Clique aqui para criar sua conta.",
   "forgotPass": "Esqueceu sua senha?",
   "forgotPass.noProblem": "Fique tranquilo!",
   "forgotPass.haveAccount":"Já tenho uma conta, quero ",
   "submit":"Enviar",
   "form.firstName":"Nome",
   "form.lastName":"Sobrenome",
   "form.email":"E-mail",
   "form.confirmEmail": "confirme seu e-mail",
   "form.password":"Senha",
   "form.retypePassword":"Confirme sua senha",
   "form.val.firstName":"Escreva seu nome",
   "form.val.firstNameRequired":"O nome é obrigatório",
   "form.val.firstNameOnlyLetters":"Somente letras",  
   "form.val.lastName":"Escreva seu sobrenome",
   "form.val.lastNameRequired":"O sobrenome é obrigatório",
   "form.val.email":"Informe seu e-mail",
   "form.val.invalidEmail":"Informe um e-mail válido",
   "form.val.emailRequired":"O e-mail é obrigatório",
   "form.val.confirmEmail":"Informe seu e-mail",
   "form.val.confirmEmailRequired":"Confirme seu e-mail",
   "form.val.confirmEmailNoMatch":"Este e-mail não é igual ao anterior",
   "form.val.passwordRequired":"Informe sua senha",
   "form.val.shortPassword":"A senha precisa ter no mínimo 8 caracteres",
   "form.val.confirmPassword":"Informe sua senha",
   "form.val.confirmPasswordRequired":"Confirme sua senha",
   "form.val.confirmPasswordNoMatch":"Erro, informe a mesma senha", 
   "form.val.invalidMobile":"Celular inválido",
   "form.val.noEmailInDesc":"Não insira dados de contato nem sites externos",
   "form.val.noURLInDesc":"Não insira sites externos",
   "form.val.noMobileInDesc":"Não insira dados de contato",
   "form.val.formHasErrors":"Por favor, corrija os erros no formulário.",
   "alert.addProduct.publish.success":"Perfeito! Aula publicada na loja com sucesso!",
   "alert.addProduct.draft.success":"Falta pouco! Sua aula está como rascunho, para vendê-la é necessário publicá-la.",
   "alert.addProduct.noAvatar":"Importante: Antes de criar uma aula, por favor insira sua foto de perfil em Configurações de Perfil",
   "alert.addProduct.error":"Erro ao publicar, tente novamente mais tarde",
   "alert.addProduct.limitOfProds": "Uau! Você chegou ao limite de aulas criadas! Estamos trabalhando para que você possa criar mais aulas.",
   "alert.updateSoldProduct.success":"Aula atualizada com sucesso!",  
   "alert.updateProduct.publish.success":"Perfeito! Aula salva e publicada na loja com sucesso!",
   "alert.updateProduct.draft.success":"Falta pouco! Aula atualizada como rascunho, para vendê-la é necessário publicá-la.",
   "alert.updateProduct.error":"Erro na atualização, tente novamente mais tarde",
   "alert.rating.success":"Nova classificação feita com sucesso!",
   "alert.rating.error":"Erro na classificação, tente novamente mais tarde",
   "alert.deleteProduct.warning":"Você tem certeza que deseja excluir este item permanentemente?",
   "alert.teach.deleteProduct.warning":"Atenção! Você tem certeza que deseja cancelar permanentemente esta turma? {br} Não é possível desfazer esta operação.",
   "alert.stud.deleteProduct.warning":"Atenção! Você tem certeza que deseja cancelar permanentemente esta aula? {br} Não é possível desfazer esta operação.", 
   "alert.deleteProduct.success":"Aula excluída com sucesso!",
   "alert.deleteProduct.error":"Erro para exluir, tente novamente mais tarde",
   "alert.disableSubscriptions.warning":"Atenção! Sua aula continuará visível na loja, mas estará desativada para receber novas inscrições.{br}Você tem certeza que deseja desativar novas inscrições para esta aula?",
   "alert.finance.desativarPagamento.warning":"Atenção! Você não poderá mais vender, nem receber por suas aulas vendidas e todas as aulas criadas sairão da loja e ficarão como rascunho.{br}Tem certeza que deseja desativar o pagamento?",
   "alert.updateAvatar.success":"Imagem do perfil actualizada com sucesso!",
   "alert.updateAvatar.warning":"O tamanho máximo permitido da imagem é de 5MB e os formatos aceitos são: .jpg, .png, .gif",  
   "updateFile.sizeError":"Tamanho máximo permitido do arquivo é 5MB",  
   "updateFile.typeError":"Formatos dos arquivos aceitos: .jpg, .png, .gif",  
   "alert.updateData.success":"Dados atualizados!",
   "alert.updateData.error":"Ocorreu um problema na atualização, por favor saia da sua conta e entre novamente mais tarde.",
   "alert.shop.addProduct.cart.success":"Aula adiconada no carrinho de compras com sucesso!",
   "alert.shop.addProduct.wishList.success":"Aula adiconada na sua lista de desejos!",
   "router.backTo.shop":"Voltar para Loja",
   "router.backTo.classes":"Voltar para Aulas",
   "product.preview":"Pré-visualização",
   "product.cImage":"Imagem de capa",
   "product.image":"imagem",
   "product.code":"Código da Aula",  
   "product.cName":"Nome da Aula",
   "product.name":"Nome",
   "product.price":"Valor",
   "product.mPrice":"Valor da Mensalidade",
   "product.discPrice":"Valor promocional",
   "product.mDiscPrice":"Valor Mensal com Desconto",
   "product.category":"Categoria",
   "product.days":"Dias",  
   "product.day.monday":"Segunda-feira",
   "product.day.tuesday":"Terça-feira",
   "product.day.wednesday":"Quarta-feira",
   "product.day.thursday":"Quinta-feira",
   "product.day.friday":"Sexta-feira",
   "product.day.saturday":"Sábado",
   "product.day.sunday":"Domingo",
   "product.time":"Período",
   "product.time.morning":"Matutino",
   "product.time.afternoon":"Vespertino",
   "product.time.night":"Noturno",
   "product.level":"Nível",
   "product.level.basic":"Básico",
   "product.level.intermediate":"Intermediário",
   "product.level.advanced":"Avançado",
   "product.hasDuration":"Tem duração",
   "product.hasDuration.yes":"Sim",
   "product.hasDuration.no":"Não",
   "product.hasDuration.startDate":"Data de início",
   "product.duration": "Duração em meses",
   "product.duration2": "Duração",
   "product.indetDuration": "Indeterminado",
   "product.Month": "Mês",
   "product.Months": "Meses",
   "product.classType":"Tipo de Aula",
   "product.classType.Particular":"Particular",
   "product.classType.Extention":"Extensão (Livre)",
   "product.classType.School":"Reforço Escolar",
   "product.classType.Tecnical":"Técnico",
   "product.classType.Prevestivular":"Pré-Vestibular",
   "product.classType.ParaConcurso":"Preparatório para Concurso",
   "product.classType.Pro":"Profissionalizante",
   "product.classType.Degree":"Graduação",
   "product.classType.PosDegree":"Pós-Graduação",
   "product.classType.MBA":"MBA",
   "product.classType.Maestry":"Mestrado",
   "product.classType.PHD":"Doutorado/PHD",
   "product.modality":"Modalidade das Aulas",
   "product.modality.studentAddress":"Casa do Estudante",
   "product.modality.teacherAddress":"Casa do Professor",
   "product.modality.institution":"Instituição de Ensino",
   "product.modality.videoCall":"Chamada de vídeo",
   "product.modality.videoOnDemand":"Vídeo Ao Vivo",
   "product.institution":"Instituição de Ensino",
   "product.institution.yes":"Sim",
   "product.institution.no":"Não",
   "product.certified":"Certificado",
   "product.certified.yes":"Sim",
   "product.certified.no":"Não",  
   "product.quantity":"Quantidade máxima de alunos",
   "product.rate":"Enviar avaliação",
   "product.perMonth":"por mês",
   "product.availability":"Disponibilidade",
   "product.tags":"Tags",
   "product.desc":"Descrição",
   "product.addDesc":"Adicione a descrição e o plano de aula",
   "product.rating":"Pontuação",
   "product.reviews":"Comentários",
   "product.noReviews":"Esta aula ainda não tem comentários.",
   "product.purchased.reviews.title": "Avalie e comente sua aula",
   "product.purchased.reviews.alert": "Neste espaço você pode avaliar e comentar sua aula. Saiba que seu comentário é muito importante para outros alunos saberem mais. Lembre-se que é proibido o uso de palavras ofensivas. Desde já agradecemos sua ajuda à nossa comunidade!",
   //"product.purchased.reviews.alert": "Este painel é usado para avaliar as aulas que você fez. lembre-se de não usar linguagem vulgar ou ofensiva. Esta nota é usada para encorajar outros alunos a assistirem às aulas. Muito obrigado pelo retorno!",
   "product.purchased.reviews.sub1": "Quantas estrelas você quer dar para sua aula?",
   "product.purchased.reviews.sub2": "Deixe seu comentário sobre sua aula e professor",
   "product.purchased.reviews.placeholder": "Adorei a aula do professor, domina o assunto e é educado...",
   "product.purchased.reviews.maxLength": "Máximo de 500 caracteres.",
   "product.comingSoon":"Em Breve",
   "product.startClass":"Entrar na minha aula",
   "product.classify":"Avaliar aula",
   "product.status":"Estado",
   "product.status.published":"Publicado",
   "product.status.draft":"Rascunho",
   "product.status.paid":"Pago",
   "product.status.nearExpired":"Próximo a vencer",
   "product.status.expired":"Vencido",
   "product.button.EditTt":"Editar publicação",
   "product.button.DeleteTt":"Excluir publicação",
   "product.subscriptions":"Inscrições",
   "button.showAll":"Mostrar todos",
   "button.publish":"Publicar",
   "button.save":"Salvar",
   "button.saveInDraft": "Rascunho",
   "button.create":"Criar",
   "button.discard":"Descartar",
   "button.cancel":"Cancelar",
   "button.edit":"Editar",
   "shop.addCart":"Adicionar ao Carrinho de Compras",
   "shop.viewCart":"Ver Carrinho de Compras",
   "shop.buyNow":"Comprar Agora",
   "shop.productDetail.inStock":"Com vagas",
   "shop.productDetail.oneStock":"{stock} vaga disponível!",
   "shop.productDetail.moreStock":"{stocks} vagas disponíveis!",
   "shop.productDetail.outStock":"Sem vagas disponíveis",
   "shop.productDetail.suscribed":"Aluno(s) Matriculado(s)",
   "shop.productDetail.alsoLike":"Aulas que você pode se interessar",
   "shop.productDetail.alreadyPurchased":"Uau! Você já é aluno desta aula! Efetue o pagamento da mensalidade através do seu painel de aluno.",  
   "shop.productDetail.buy.error":"Ops! Ocorreu um erro no processo. Tente novamente mais tarde ou entre em contato com o suporte.",
   "shop.title":"Encontre sua Aula",
   "shop.filter.searchProducts":"Buscar Aulas",
   "shop.filter.featured":"Destacadas",
   "shop.filter.popular":"Mais Vendidos",
   "shop.filter.lowPrice":"Menor Preço",
   "shop.filter.highPrice":"Maior Preço",
   "shop.filter.showLess":"Mostrar menos",
   "shop.filter.showMore":"Mostrar mais",
   "shop.filter.below":"Até",
   "shop.filter.above":"Acima de",
   "shop.filter.all":"Todos",
   "shop.filter.priceRange":"Faixa de Preço",
   "shop.filter.priceRange.go":"Ok",
   "shop.filter.noDuration":"Sem data de finalização",
   "shop.filter.durationRange":"Duração média",
   "shop.filter.certified":"Mostrar somente aulas com certificados",
   "shop.filter.institution":"Mostrar somente instituições de ensino",  
   "shop.filter.clearFilters":"Limpar todos os filtros", 
   "socialMedia.shareNow":"Compartilhar Agora",
   "socialMedia.facebook":"Facebook",
   "socialMedia.instagram":"Instagram",
   "socialMedia.followFacebook":"Curta a página para fazer parte da nossa comunidade no Facebook.",
   "socialMedia.followInstagram":"Siga-nos no Instagram para acompanhar todas nossas novidades.",
   "socialMedia.shareTitle":"Acompanhe nossa comunidade: ",
   "subscribe.title":"Receba todas as promoções e novidades!",
   "subscribe.desc":"Mantenha-se atualizado com nossas novidades!",
   "subscribe.name":"Escreva seu nome...",
   "subscribe.email":"Escreva seu e-mail...",
   "subscribe.student":"Aluno",
   "subscribe.teacher":"Professor",
   "subscribe.popUp.title":"Não perca",
   "subscribe.popUp.desc":"Em breve lançaremos as aulas e esperamos poder contar com você.",
   "subscribe.popUp.close":"Depois",
   "contact.pageTitle":"Fale Conosco",
   "contact.pageDesc":"Dúvida, reclamação ou sugestão? Mande seu recado, queremos escutar você.",
   "contact.contactInfo":"Entre em Contato:",
   "contact.contactDesc":"Adoraríamos saber mais sobre a sua experiência em nossa plataforma. Entre em contato conosco através de um dos nossos canais. Estaremos prontos para atender você.",
   //"contact.whatsapp": "WhatsApp",
   "contact.tel":"(11) 98315-0988",
   //"contact.mail":"E-mail",
   "contact.mail":"contato@todasaulas.com",
   //"contact.ourOffice":"Nossa Central",
   "contact.addressDesc":"Vila Mariana, São Paulo, Brasil.",
   "contact.whereOperate":"Nossa abrangência",
   "contact.followInSocial":"Nossas Redes Sociais",
   "terms.title":"Termos e Condições",
   "terms.desc":"Caso haja dúvidas após a leitura, por favor entre em contato conozco",
   "privacyPol.title":"Política de Privacidade",
   "privacyPol.desc":"Cuidamos das suas informações pessoais", 
   "faq.title":"Perguntas Frequentes",
   "faq.desc":"Aqui você encontra algumas respostas para suas dúvidas",  
   "faq.submitRequest":"Fale conosco",
   "bannerCat.seeMore":"Ver mais",
   "bannerCat.shopNow":"Comprar agora",
   "bannerCat.seeAll":"Ver todas",
   "bannerProf.title":"Quero ensinar",
   "bannerProf.desc":"Não fique de fora do maior grupo de professores que dá aulas online em tempo real para todo o Brasil.",
   "bannerProf.button":"Cadastre-se gratuitamente agora!",  
   "404.desc":"Ops, não encontramos o que você procura",
   "404.toHome":"Voltar para Início",
   "yes":"Sim",
   "no":"Não",
   "close": "Fechar",
   "iAgree": "Aceito",
   "retrieve.data.error": "Falha ao recuperar dados. Tente novamente mais tarde por favor",
   "teach.finance.activePayment.title" : "Ativação do Meio de Pagamento",
   "teach.finance.activePayment.subTitle" : "Escolha um Meio de Pagamento da sua preferência.",
   "teach.finance.payment.mpAccount": "Ativar conta MercadoPago",
   "teach.finance.activePayment.desc": "Para receber os pagamentos dos seus alunos, ative sua conta agora mesmo. Veja como funciona {link}.",
   "teach.finance.unlinkPayment.mpAccount": "Desvincular Conta MercadoPago",
   "teach.finance.unlinkPayment.desc": "Ao desativar a conta, automaticamente, suas aulas publicadas serão pausadas para venda na loja. Atenção: Caso já tenha aulas vendidas, seus pagamentos serão suspensos.",
   "button.link": "Ativar",
   "button.unlink": "Desativar",  
   "alert.stud.warning.payDateClose": "O pagamento de uma ou mais aula(s) está próximo ao vencimento, não deixe para a última hora!",
   "alert.stud.error.mustPay": "Ai não! Seu pagamento venceu, pague agora mesmo para continuar estudando!",
   "alert.stud.error.teacherRecentlyDesubscribed":"Important!  Você deixou de ter acesso a alguma(s) aula(s), porque o Professor não tem o meio de pagamento ativado.",
   "alert.stud.error.teacherNoLinked": "Quase deu certo! Avise ao seu Professor que ative o meio de pagamento para você!",
   "alert.teach.finance.vinculate.success": "Parabéns!!! Você já pode publicar suas aulas!",
   "alert.teach.finance.vinculate.error": "Ops! Ocorreu um erro no processo. Tente novamente mais tarde ou entre em contato com o suporte.",
   "alert.teach.finance.vinculate.errorOtherAccount": "Ops! Esta conta de pagamento já está vinculada com outro usuário. Utilize uma nova conta de pagamento para ativar suas vendas.",
   "alert.teach.finance.unlink.success": "Sua conta foi desvinculada com sucesso! Lembre-se de que não é possível realizar vendas sem vincular o meio de pagamento.",
   "alert.title.info":"Informação",
   "alert.title.warning":"Se Liga!!",
   "alert.title.error":"Aviso de Erro",
   "alert.teach.draft.info":"Você tem aula(s) em rascunho, publique-a(s) agora mesmo e inicie suas vendas.",
   "alert.teach.error.mustSubscribe":"Ative o meio de pagamento para liberar a publicação e venda da(s) sua(s) aula(s) {link}.",
   "alert.teach.sold.error.subsForTeach":"Ação Necessária: Ative o meio de pagamento para acessar sua(s) aula(s) vendida(s) {link}.",
   "alert.teach.error.mustSubsOrWillBlocked":"Importante: Restam apenas {days} dia(s) de acesso à(s) aula(s) vendida(s). Ative o meio de pagamento agora {link}! ;)",
   "alert.shop.warning.pausedClass":"Esta aula foi pausada temporariamente e não pode ser comprada. Já estamos analisando alguns professores para oferecer aulas como esta e atender às suas expectativas!",
   "alert.teach.edit.error.soldNoDraft": "Ops! Não é possível deixar como rascunho uma aula vendida! Entre em contato com o suporte se tiver alguma dúvida.",
   "alert.teach.products.error.soldNoDelete": "Ops! Não é possível excluir uma aula vendida! Entre em contato com o suporte se tiver alguma dúvida.",
   "cookieNotification.title": "Todas Aulas Política de Cookies e Privacidade",
   "cookieNotification.readPolicy": "Leia a Política de Privacidade",
   "cookieNotification.updatePolicy": "Todas Aulas atualizou nossa {link}, a partir de julho de 2022. Essas atualizações incluem informações sobre os dados pessoais que coletamos, como podemos lidar com esses dados pessoais e direitos que você pode ter em relação aos seus dados pessoais. Seu uso continuado do nosso site significa que você aceita a Política de Privacidade atualizada."
}
