/**
 * Dasboard Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// import helmet
import { Helmet } from "react-helmet";

// async components
import {
   //AsyncInvoiceListComponent,
   //AsyncReportsComponent,
   AsyncFirstInfoComponent,
   AsyncProductsGridComponent,
   AsyncSoldProductsGridComponent,
   AsyncProductSoldDetailsComponent,
   AsyncMyFinancesComponent,
   AsyncProductAddComponent,
   AsyncProductEditComponent,
   //AsyncProfileDetailComponent,
   AsyncPageNotFoundComponent
} from '../../util/AsyncRoutes';

// private Routes helper
import PrivateRoute from './../../util/PrivateRoutes';

const AdminPanel = ({ match }) => {
   return (
      <div className="dashboard-wrapper">
		<Helmet>
			<title>Painel do Professor - Todas Aulas</title>
		</Helmet>
         <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/first-info`} />
			<PrivateRoute path={`${match.url}/first-info`} component={AsyncFirstInfoComponent} />
            {/* <PrivateRoute path={`${match.url}/reports`} component={AsyncReportsComponent} />
            <PrivateRoute path={`${match.url}/invoices`} component={AsyncInvoiceListComponent} /> */}
            <PrivateRoute path={`${match.url}/products`} component={AsyncProductsGridComponent} />
            <PrivateRoute path={`${match.url}/soldProducts`} component={AsyncSoldProductsGridComponent} />
            <PrivateRoute path={`${match.url}/productSoldDetails/:id`} component={AsyncProductSoldDetailsComponent} />
            <PrivateRoute path={`${match.url}/myFinances/:state?`} component={AsyncMyFinancesComponent} />
            <PrivateRoute path={`${match.url}/product-add`} component={AsyncProductAddComponent} />
            <PrivateRoute path={`${match.url}/product-edit/:type/:id`} component={AsyncProductEditComponent} />
            {/* <PrivateRoute path={`${match.url}/account`} component={AsyncProfileDetailComponent} /> */}
			<Route path={`${match.url}/*`} component={AsyncPageNotFoundComponent} />
         </Switch>
      </div>
   )
}

export default AdminPanel;