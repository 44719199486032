/**
 * App Routes
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import $ from 'jquery';
import { Scrollbars } from 'react-custom-scrollbars';
//import  AutoSizer  from "react-virtualized-auto-sizer";

// actions
import { collapsedSidebarAction } from '../../actions/action';
import AdminNav from '../layouts/admin-nav/AdminNav';
import AdminSidebar from '../layouts/admin-sidebar';

class RctAdminPanel extends Component {

   UNSAFE_componentWillMount() {
      this.updateDimensions();
   }
   componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
   }
   updateDimensions = () => {
      this.setState({ windowWidth: $(window).width(), windowHeight: $(window).height() });
   }
   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
   }
   UNSAFE_componentWillReceiveProps(nextProps) {
      const { windowWidth } = this.state;
      if (nextProps.location !== this.props.location) {
         if (windowWidth <= 1199) {
            this.props.collapsedSidebarAction(false);
         }
      }
   }
   componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
         window.scrollTo(0, 0);
      }
   }
   renderPage() {
      const { children } = this.props;
      return (
		// <div style={{ display: "flex", height: "calc(100vh - 64px)"}}>
        // 	<div style={{ flex: "1 1 auto" }}>
		// 		<AutoSizer /*defaultHeight="30"*/> {({ width, height }) => {
		// 			return (
         				<Scrollbars
							//renderThumbVertical={props => <div {...props} style={{height: "50px !important", position: "relative",display: "block",width: "100%",cursor: "pointer",borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.4)", height:"30px !important"}} />}
							className="rct-scroll"
							//autoHeight
							//autoHeightMin={300}
							//autoHide
							//autoHideDuration={100}
							//style={{ width, height }}
							//thumbMinSize={30}
							style={this.getScrollBarStyle()}
						>
							<div className="rct-page-content p-md-30 px-15 py-30">
								{children}
							</div>
         				</Scrollbars>
		//  			);
		// 		}}
	  	// 		</AutoSizer>
	  	// 	</div>
      	// </div>
      );
   }
   //Scrollbar height
   getScrollBarStyle() {
      return {
         height: 'calc(100vh - 64px)'
      }
   }
   // render header
   renderHeader() {
      return <AdminNav />
   }
   // render header
   renderSidebar() {
      return <AdminSidebar />
   }
   render() {
      const { navCollapsed, rtlLayout } = this.props.appSettings;
      const { windowWidth } = this.state;
      return (
         <div className="admin-wrapper">
            <Sidebar
               sidebar={this.renderSidebar()}
               open={windowWidth <= 1199 ? navCollapsed : false}
               docked={windowWidth > 1199 ? !navCollapsed : false}
               pullRight={rtlLayout}
               onSetOpen={() => this.props.collapsedSidebarAction(false)}
            >
               <div className="rct-app-content">
                  <div className="app-header">
                     {this.renderHeader()}
                  </div>
                  <div className="rct-page">
                     {this.renderPage()}
                  </div>
               </div>
            </Sidebar>
         </div>
      );
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   return { appSettings }
}

export default withRouter(connect(mapStateToProps, {
   collapsedSidebarAction,
})(RctAdminPanel));