/**
 * App Constants
*/

export default {
   AppLogo: require('../assets/images/logo-todasaulas.png'),       			// App logo
   urlBase: 'https://www.todasaulas.com',
   facebookPage: 'https://www.facebook.com/TodasAulas',
   instagramPage:'https://www.instagram.com/TodasAulas/',
   backEndBaseURL: process.env.REACT_APP_CONFIG_BACK_END_BASE_URL, //Prod:'https://tawebapi-vbufqauisq-uc.a.run.app/' Test:'https://webapitest-vbufqauisq-uc.a.run.app/' local: 'http://localhost:8080/'
   rtlLayout: false,                                             				// RTL Layout
   adminLayout: false,                                           				// Admin Layout
   navCollapsed: false,                                          				// Sidebar Nav Layout
   algoliaConfig: {                                              				// Algolia configuration
      appId: 'KU4DS3PZOG',
      apiKey: '310ea94d72a318e51a420ec26d5de1c5',
	  indexPopularDesc: process.env.REACT_APP_CONFIG_INDEX_POPULAR_DESC, //Production: TAP test: 'dev_TA'
	  indexPriceAsc: process.env.REACT_APP_CONFIG_INDEX_PRICE_ASC, //Production: TAP_price_asc test: 'price_asc'
	  indexPriceDesc: process.env.REACT_APP_CONFIG_INDEX_PRICE_DESC //Production: TAP_price_desc test: 'price_desc'
   },
   // MercadoPago client
   mp: new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY, { locale: 'pt-BR' }),
   // Default locale
   locale: {
      format: "DD-MM-YYYY HH:mm:ss",
      shortFormat: "DD-MM-YYYY",
	   shortFormat2: "DD/MM/YYYY",
      locale: 'pt', //'en',
      name: 'Portuguese', //'English',
      icon: 'pt', //'en',
   },
   // Footer about description
   AboutUs: 'Acreditamos que no mundo globalizado em que vivemos, o ensino-aprendizagem deve estar ao alcance de todos. Sabemos que a limitação para aprender pode pausar ou cancelar sonhos. Vemos milhares de pessoas dispostas a aprender o que outras têm para ensinar e é com este objetivo que nossa plataforma está aberta, de maneira universal, para que qualquer pessoa possa ser professor(a) e/ou aluno(a), ensinando e aprendendo um com o outro a todo momento. Agradecemos por você ser parte desse movimento!',
   // Copyright text
   CopyrightText: '© Todos os Direitos Reservados | Com amor por Todas Aulas - 2020 por um mundo melhor'
}