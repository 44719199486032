import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

const PrivateRoute = ({component: Component, ...rest}) => {
	
	const {authentication} = rest;

	return (
		<Route {...rest} render={props => (
		(authentication.uid) ? (
			<Component {...props}/>
		) : (
			<Redirect to={{ pathname: '/sign-in', state: {from: props.location}}}/>
		)
		)}
		/>
	);
};
    
function mapStateToProps(state) {
	return {
	  authentication: state.firebase.auth,
	};
}
  
export default withRouter(connect(mapStateToProps)(PrivateRoute));