/**
 * Helpers Method
 */
import { store } from "../index";
import React from 'react';
import NumberFormat from 'react-number-format';
import Resizer from 'react-image-file-resizer';
import firebase from '../firebase';
import moment from 'moment';
import appConfig from '../constants/AppConfig'

/**
 *  function to check product is exits in cart or not
 */
export function isProductExist(ID) {
   let exists = false
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         if (cartItem.productID === ID) {
            exists = true
         }
      }
   }
   return exists;
}

/**
 *  function to check product is exits in wishlist or not
 */
export function productExitsInWishlist(ID) {
   let exist = false
   let wishlist = store.getState().ecommerce.wishlist;
   if (wishlist && wishlist.length > 0) {
      for (const item of wishlist) {
         if (item.productID === ID) {
            exist = true
         }
      }
   }
   return exist;
}

/**
 * Get subTotal Price
 */
export function getSubTotalPrice() {
   let subTotalPrice = 0;
   let cart = store.getState().ecommerce.cart;
   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         subTotalPrice += cartItem.totalPrice;
      }
      return subTotalPrice;
   }
}

/**
 * get Total Price
 */
export function getTotalPrice() {
   const { tax, shipping } = store.getState().ecommerce;
   const totalPrice = getSubTotalPrice() + shipping + tax;
   return totalPrice.toFixed(2);
}


/**
 *  function to format price number
 */

export function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator={'.'}
		decimalSeparator={','}
		decimalScale={2}
		isNumericString
		//prefix="R$"
	  />
	);
}
//   NumberFormatCustom.propTypes = {
// 	inputRef: PropTypes.func.isRequired,
// 	name: PropTypes.string.isRequired,
// 	onChange: PropTypes.func.isRequired,
//   };

/**
 *  function to calculate % discount
 */
export function calculateDiscount(price, discPrice) {
	return Math.round(100 - ((discPrice*100)/price));
 }

/**
 *  function to resize image
 */
 export const resizeFile = (photo) => new Promise(resolve => {
    Resizer.imageFileResizer(photo, 300, 300, 'JPEG', 95, 0,
    uri => {
		const convertedBlobFile = new File([uri], photo.name, { type: photo.type, lastModified: Date.now()})
      	resolve(convertedBlobFile);
    },
    'blob'
    );
});

/**
 *  function to transform days to numbers of the day in an array
 */

let getDayss = (days, intl) => {
	const numberOfdays = [];
	
	days.forEach((day, i) => {
		switch (day) {
			case intl.formatMessage({ id: "product.day.monday"}) :
				numberOfdays.push(1);
			break;
			case intl.formatMessage({ id: "product.day.tuesday"}) :
				numberOfdays.push(2);
			break;
			case intl.formatMessage({ id: "product.day.wednesday"}) :
			 	numberOfdays.push(3);
			break;
			 case intl.formatMessage({ id: "product.day.thursday"}) :
				numberOfdays.push(4);
			break;
			case intl.formatMessage({ id: "product.day.friday"}) :
			 	numberOfdays.push(5);
			break;
			case intl.formatMessage({ id: "product.day.saturday"}) :
			 	numberOfdays.push(6);
			break;
			case intl.formatMessage({ id: "product.day.sunday"}) :
			 	numberOfdays.unshift(0);
			break;
			default:	
			break;
		}
	})
	return numberOfdays;
}
//injectIntl(getDays);


function NextDate(goalDay)
{
	let today = new Date();
	//console.log(today);
	let dayOfToday = new Date().getDay();
	//console.log(dayOfToday);
	if (goalDay < dayOfToday)
		goalDay += 7;
	let goalDate = new Date(today.setDate(today.getDate() + (goalDay - dayOfToday)))
	//console.log(goalDate);
	return goalDate; 
}
/**
 *  function to calculate next day of the array
 */
 export function NextDayOfClass(days, intl){
	
	const numberOfDays = getDayss(days, intl)
	
	let today = new Date().getDay();
	let goalDay;

	let found = false;

	for(let day of numberOfDays) {

		if (today <= day){
	 		goalDay = day;
	 		found = true;
	 		break;
	 	}
	}
	if (!found) {
	 	goalDay = numberOfDays[0]; 
	}
	//console.log(goalDay)
	return NextDate(goalDay);
}

//This is the way to recover timestamp from firebase (timestamp) to a javascript date (new date())
export function backEndTimeStampToJSDate(backEndTimeStamp){
	return new firebase.firestore.Timestamp(backEndTimeStamp._seconds, backEndTimeStamp._nanoseconds).toDate();
}
//This is the way to show on a format the timestamp date from firebase
export function backEndTimeStampToDateFormated(backEndTimeStamp){
	return moment.unix(backEndTimeStamp._seconds).format(appConfig.locale.shortFormat2);
}

// This is the way to save the date (new date()) as timestamp on firebase
// On firebase you will see the date data type
export function jsDateToBackEndTimeStamp(jsDate){
	return jsDate._d;
}

export const diffBetwTodayAnd = (firestoreDate) => {
	var lastDatePaid;
	if (firestoreDate.seconds){lastDatePaid = moment.unix(firestoreDate.seconds).format('MM/DD/YY');}
	if (firestoreDate._seconds){lastDatePaid = moment.unix(firestoreDate._seconds).format('MM/DD/YY');}
	var today = moment();
    var now = moment(today, 'MM/DD/YY');
	//See for a deprecated handle time with moment
    return moment(now, 'MM/DD/YY').diff(lastDatePaid, 'days');
}

export const generateInitialsAvatar = (text) => new Promise(resolve => {
	let foregroundColor = 'Black'
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
  
	canvas.width = 200;
	canvas.height = 200;
  
	// Draw background
	
	var randomBackground = Math.floor(Math.random()*16777215).toString(16);
	var rgb = parseInt(randomBackground, 16);   // convert rrggbb to decimal
	var r = (rgb >> 16) & 0xff;  // extract red
	var g = (rgb >>  8) & 0xff;  // extract green
	var b = (rgb >>  0) & 0xff;  // extract blue
  
	var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  
	//If the random hexa is darker
	if (luma < 50) {
	  foregroundColor = "White"
	}
	
	context.fillStyle = '#'+ randomBackground;
	context.fillRect(0, 0, canvas.width, canvas.height);
  
	// Draw text
	context.font = "bold 100px Assistant";
	context.fillStyle = foregroundColor;
	context.textAlign = "center";
	context.textBaseline = "middle";
	context.fillText(text, canvas.width / 2, canvas.height / 2);
  
	var dataURI = canvas.toDataURL("image/png");

	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	var byteString = atob(dataURI.split(',')[1]);
	
	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	
	// write the bytes of the string to an ArrayBuffer
	var ab = new ArrayBuffer(byteString.length);
	
	// create a view into the buffer
	var ia = new Uint8Array(ab);
	
	// set the bytes of the buffer to the correct values
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	
	// write the ArrayBuffer to a blob, and you're done
	var blob = new Blob([ab], {type: mimeString});

	resolve(blob);
	

});