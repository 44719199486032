/**
 * store
 */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import {reduxFirestore, getFirestore} from 'redux-firestore';
import {reactReduxFirebase, getFirebase} from 'react-redux-firebase';
import firebase from '../firebase'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore() {
	const store = createStore(
		reducers,
		composeEnhancer(
			applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
			reduxFirestore(firebase),
			reactReduxFirebase(firebase, {useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true})
		)
	);
	return store;
}

